<template>
    <div class="container">
        <section>
            <b-sidebar
                    type="is-light"
                    fullheight
                    left
                    :open.sync="openSearch"
                    :can-cancel="[false, true, false]">
                <div class="SideContainer">

                    <div class="buttons">
                        <b-button @click="openSearch = !openSearch"
                                  type="is-success"
                                  size="is-medium"
                                  expanded
                                  outlined
                        icon-left="arrow-left">
                            Cacher
                        </b-button>
                    </div>


                    <GeomFilter
                        mode="sitesgeres"
                    >
                    </GeomFilter>

                </div>
            </b-sidebar>
        </section>

        <section>
            <b-sidebar
                    type="is-light"
                    fullheight
                    left
                    :open.sync="openPrefs"
                    :can-cancel="[false, true, false]">
                <div class="SideContainer">

                    <div class="buttons">
                        <b-button @click="openPrefs = !openPrefs"
                                  type="is-success"
                                  size="is-medium"
                                  expanded
                                  outlined
                                  icon-left="arrow-left">
                            Cacher
                        </b-button>
                    </div>

                    <MapLayers
                        mode="sitesgeres"
                    >
                    </MapLayers>

                </div>
            </b-sidebar>
        </section>

        <div class="columns">
            <div class="column is-4">

                <div class="buttons">
                    <b-field>
                            <b-button @click="openSearch = !openSearch"
                                      icon-left="search">
                                Recherche
                            </b-button>
                            <b-button @click="openPrefs = !openPrefs"
                                      icon-left="layer-group">
                                Affichage
                            </b-button>

                            <b-select
                                placeholder="Sélectionner un territoire"
                                v-model="territoire"
                                @change.native="changeTerritoire()"
                            >
                                <option
                                    v-for="option in dicts.territoires"
                                    :value="option.idterritoire"
                                    :key="option.idterritoire">
                                    {{ option.libterritoire }}
                                </option>
                            </b-select>

                    </b-field>
                </div>

                <!-- Help for mouse action -->
                <div v-if="!plotToEdit.length && !addSite">
                    <img
                        src="@/assets/img/actions_souris.svg"
                        alt="visual-help"
                    width="80%"/>
                    <p>
                        Les <b>cercles rouges</b> représentent les parcelles non rattachées à un site CEN.<br><br>
                        Un <u>clic-gauche</u> sur le cercle permet de rattacher la parcelle à un site existant.<br>
                        De même, un <u>clic-gauche</u> sur une parcelle déjà affectée à un site permet de changer son affectation.
                    </p>
                    <br>
                    <p>
                        Un <u>clic-droit</u> sur une parcelle déjà affectée à un site permet d'afficher les informations
                        du site concerné. Elle peuvent également être modifiées.
                    </p>
                    <br>
                    <p>
                        Si il s'agit d'<b>un nouveau site</b>, il faut dans un premier temps le créer. Il sera ensuite visible
                        dans la liste des sites lors de votre prochain rattachement.
                    </p>
                    <br>
                </div>


                <b-switch
                    type="is-success"
                    v-model="addSite"
                    v-if="!plotToEdit.length && !addSite"
                >
                    Créer un nouveau site
                </b-switch>

                <!-- Rattacher les parcelles à un site -->
                <div v-if="plotToEdit.length">
                    <hr>
                    <h2 class="subtitle">Rattacher des parcelles sans site</h2>
                    <ul>
                        <li
                            v-for="plot in plotToEdit"
                            :value="plot"
                            :key="plot">
                            <b-icon icon="info-circle" type="is-success"></b-icon>
                             {{ plot }}
                        </li>
                    </ul>
                    <hr>

                    <p>
                        <b-icon icon="lightbulb" type="is-info"></b-icon>
                        Si le site appartient déjà à un site CEN et que vous laisser la sélection vide,
                        la ou les parcelles seront simplement détachées du site après l'enregistrement.
                    </p>

                    <hr>

                    <b-field label="Rattachement au site CEN">
                        <b-autocomplete
                            v-model="siteString"
                            placeholder="Rechercher une site existant"
                            keep-first
                            open-on-focus
                            :data="getFilteredSites"
                            field="nom_site"
                            @select="option => siteSelectedFromList = option.idsite"
                        >
                        </b-autocomplete>
                    </b-field>

                    <b-button
                        type="is-success"
                        @click="save()"
                    >
                        Enregistrer
                    </b-button>&nbsp;
                    <b-button
                        type="is-warning"
                        @click.native="resetAttach()"
                    >
                        Annuler
                    </b-button>
                </div>

                <div v-if="addSite && !plotToEdit.length">

                    <h2 class="subtitle">Généralités</h2>
                        <b-field label="Code" label-position="on-border" custom-class="required">
                            <b-input
                                v-model="plotData.codesite"
                                maxlength="6"
                                required
                                :disabled="plotData.idsite"
                            ></b-input>
                        </b-field>
                        <b-field label="Nom" label-position="on-border" custom-class="required">
                            <b-input v-model="plotData.nom_site" maxlength="150" required></b-input>
                        </b-field>
                        <b-field label="Description" label-position="on-border">
                            <b-input v-model="plotData.description_site" maxlength="200" type="textarea"></b-input>
                        </b-field>
                    <b-field label="Habitat majoritaire FCEN" label-position="on-border" custom-class="required">
                        <b-select placeholder="Sélectionner" v-model="plotData.idmilieu" required>
                            <option
                                v-for="option in this.dicts.ftypemilieu"
                                :value="option.id_type_milieu"
                                :key="option.id_type_milieu">
                                {{ option.libelle_type_milieu }}
                            </option>
                        </b-select>
                    </b-field>

                    <h2 class="subtitle">Liens réglementaires</h2>
                    <b-field grouped>
                        <b-field label="Réserve Naturelle" label-position="on-border" custom-class="required">
                            <b-select placeholder="Sélectionner" v-model="plotData.idlien_reserve">
                                <option
                                    v-for="option in this.dicts.reserves"
                                    :value="option.idlien_reserve"
                                    :key="option.idlien_reserve">
                                    {{ option.liblien_reserve }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-checkbox v-model="plotData.ens">Espace Naturel Sensible</b-checkbox>
                        <b-checkbox v-model="plotData.cdl">Terrain du CdL</b-checkbox>
                        <b-checkbox v-model="plotData.tm">Terrain Militaire</b-checkbox>
                    </b-field>

                    <h2 class="subtitle">Caractéristiques</h2>
                    <b-field grouped>
                        <b-field label="Intérêt géologique" label-position="on-border" custom-class="required">
                            <b-select placeholder="Sélectionner" v-model="plotData.idgeol">
                                <option
                                    v-for="option in this.dicts.interetsgeologiques"
                                    :value="option.idgeol"
                                    :key="option.idgeol">
                                    {{ option.libgeol }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field label="Surface en libre évolution (m²)" label-position="on-border">
                            <b-input type="number" v-model="plotData.evolutionlibre">
                            </b-input>
                        </b-field>
                    </b-field>

                    <h2 class="subtitle">Fréquentation du public</h2>
                    <b-field grouped>
                        <b-field label="Ouverture au public" label-position="on-border">
                            <b-select placeholder="Sélectionner" v-model="plotData.idouverture">
                                <option
                                    v-for="option in this.dicts.ouverturepublic"
                                    :value="option.idouverture"
                                    :key="option.idouverture">
                                    {{ option.libouverture }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field grouped>
                            <b-checkbox v-model="plotData.sensible">Sensibilité du site</b-checkbox>
                            <b-checkbox v-model="plotData.zone_quietude">Présence de zones de quiétude</b-checkbox>
                        </b-field>
                    </b-field>

                    <b-field label="Explications du caractère sensible" label-position="on-border">
                        <b-input type="textarea" v-model="plotData.rq_sensible">
                        </b-input>
                    </b-field>

                    <b-button
                        type="is-success"
                        @click="saveNewSite()"
                    >
                        Enregistrer
                    </b-button>&nbsp;
                    <b-button
                        type="is-warning"
                        @click="resetSiteForm()"
                    >
                        Annuler
                    </b-button>
                </div>

                <div v-if="attachPlot">

                </div>



            </div>
            <div class="column is-8">
                <MapSites
                    mode="sitesgeres"
                ></MapSites>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import MapSites from '../components/MapSites.vue'
    import MapLayers from "../components/MapLayers";
    import GeomFilter from "../components/GeomFilters";
    import {bus} from '@/main';
    import store from "@/components/store";

    export default {
        components: {
            GeomFilter,
            MapLayers,
            MapSites,
        },
        created() {
            bus.$on('getInfoSite', async (e) => {
                this.addSite = true
                this.resetAttach();
                try {
                    let r = await this.$axios.get(`getInfoSite?id=${e}`)
                    this.plotData = r.data
                }
                catch (err) {
                    console.log(err)
                }
            });
            bus.$on('attachThisPlot', async (e) => {
                // Reset the form for nex site create
                this.resetSiteForm()
                const id = e;
                if (this.plotToEdit.includes(id)) {
                    console.log("exist")
                    const index = this.plotToEdit.indexOf(id);
                    if (index !== -1) {
                        this.plotToEdit.splice(index, 1);
                    }
                } else {
                    console.log("not exist")
                    this.plotToEdit.push(id)
                }
                if(this.plotToEdit.length === 0) {
                    this.closiestSites = []
                }
                if(this.plotToEdit.length === 1) {
                    await this.getClosiestSites()
                }

            });
        }, mounted() {
            this.getOptions();
        },
        beforeDestroy() {
            bus.$off('attachThisPlot');
            bus.$off('getInfoSite');


        },
        computed: {
            // Search filter on Aires
            getFilteredSites() {
                    return this.closiestSites.filter((option) => {
                        return option.nom_site
                            .toString()
                            .toLowerCase()
                            .indexOf(this.siteString.toLowerCase()) >= 0
                    })
            },
        },
        data() {
            return {
                // Dict for lists and autocomplete
                dicts: {},
                closiestSites: [],
                // Autocomplete variables for sites search
                siteString: '', // User input
                siteSelectedFromList: null,
                // Switches button data
                addSite: false,
                attachPlot: false,
                // Sidebar menu
                openSearch: false,
                openPrefs: false,
                // Selected plots
                plotToEdit: [],
                // Form
                plotData: {
                },
                mode: String,
                // Territoire
                territoire: null
            }
        },
        methods: {
            async changeTerritoire() {
                console.log("selected option : ", this.territoire)
                try {
                    await store.commit('SET_TERRITOIRE', this.territoire);
                    this.resetAttach();
                } catch (err) {
                    console.log(err)
                }
            },
            validateInput(input) {
            // Convert the input to uppercase
                const upperCaseInput = input.toUpperCase();
                // Regular expression pattern to match "24COLY" format
                const pattern = /^[0-9]{2}[A-Z]{4}$/;
                // Test if the uppercase input matches the pattern
                return pattern.test(upperCaseInput);
            },
            resetAttach() {
                this.plotToEdit = [];
                this.siteSelectedFromList = null;
                this.siteString = '';
                console.log('refreshStoredMFUParcelles emited')
                bus.$emit('refreshStoredMFUParcelles')
            },
            resetSiteForm() {
                this.plotData = {};
                this.addSite = false;
            },
            async getOptions() {
                try {
                    let r = await this.$axios.get('siteGeresDicts')
                    this.dicts = r.data
                } catch (err) {
                    console.log(err)
                }

            },
            async getClosiestSites() {
                try {
                    let r = await this.$axios.get(`closiestSites?id=${this.plotToEdit[0]}`)
                    this.closiestSites = r.data
                } catch (err) {
                    console.log(err)
                }
            },
            async saveNewSite() {
                // Check non nullable values
                if (
                    !this.plotData.idgeol ||
                    !this.plotData.idlien_reserve ||
                    !this.plotData.idmilieu ||
                    !this.plotData.nom_site ||
                    !this.plotData.codesite ||
                    !this.validateInput(this.plotData.codesite)
                ) {
                    this.$buefy.snackbar.open({
                        duration: 5000,
                        message: 'Champs obligatoires non renseignés !',
                        type: 'is-danger',
                        position: 'is-bottom-left'
                    });
                    return;
                }
                try {
                    this.plotData.codesite = this.plotData.codesite.toUpperCase();
                    await this.$axios({
                        method: 'POST',
                        url: 'createNewSite',
                        data: this.plotData
                    })
                    this.resetSiteForm()
                } catch (err) {
                    console.log(err)
                    this.$buefy.snackbar.open({
                        duration: 5000,
                        message: 'Ce code site existe déjà !',
                        type: 'is-danger',
                        position: 'is-bottom-left'
                    });
                }
            },
            async save() {
                try {
                    await this.$axios({
                        method: 'PUT',
                        url: 'attachPlotToSite',
                        data: {
                            ids: this.plotToEdit,
                            site: this.siteSelectedFromList
                        }
                    })
                    // Reload the layer
                    bus.$emit('refreshStoredMFUParcelles')
                    await this.getClosiestSites();
                    // Reset fields
                    this.plotToEdit = [];
                    this.siteSelectedFromList = null;
                    this.siteString = '';
                } catch (err) {
                    console.log(err)
                }
            }
        }
    }
</script>

<style scoped>

.container {
    margin: 10px 10px 10px 10px;
    max-width: 100% !important;
    max-height: 100% !important;
}

.SideContainer {
    margin: 30px 10px 10px 10px;
}

</style>

<style>

.field .label.required::after {
    content: " *";
    color: #ec0000;
}

.b-sidebar .sidebar-content {
    background-color: #faffd3 !important;
    box-shadow: 5px 0px 13px 3px rgba(10, 10, 10, 0.1);
    width: auto !important;
    z-index: 39;
}

</style>
