<template>
    <div id="layers">
        <h1 class="title">Affichage</h1>
        <div class="buttons">
            <b-button
                    type="is-success"
                    expanded
                    @click="savePrefs()">
                Enregistrer mes préférences
            </b-button>
            <b-button
                    type="is-warning"
                    expanded
                    @click="reloadPrefs()">
                Recharger mes préférences
            </b-button>
        </div>
        <b-tabs type="is-boxed">
            <b-tab-item label="Analyse thématique">
                <p>Sélectionnez le mode d'affichage à utiliser.</p>
                <br>

                <!-- Animation foncière <-->
                <div v-if="mode === 'animationfonciere'">
                    <b-field label="Informations à afficher">
                        <b-select
                            v-model="color"
                            placeholder="Choisir..."
                            @change.native="changeThemeColor(color)">
                            <option value="categorie">Animation foncière</option>
                            <option value="interet">Intérêt</option>
                        </b-select>
                    </b-field>
                    <b-field label="Légende">
                        <ul>
                            <li v-for="item in legendeitems" :key="item.id">
                                <svg width="30" height="20">
                                    <rect width="30"
                                          height="20"
                                          style="fill-opacity:.75;stroke-width:3;stroke:rgb(0,0,0)"
                                          v-bind:style="{ fill: item.color}"/>
                                </svg>
                                {{ item.lib }}
                            </li>
                        </ul>
                    </b-field>
                </div>

                <!-- Sites CEN -->
                <div v-if="mode === 'sitesgeres'">
                    <b-field label="Informations à afficher">
                        <b-select
                            v-model="colorsite"
                            placeholder="Choisir..."
                            @change.native="changeThemeColor(colorsite)">
                            <option value="statut">Type de MFU</option>
                            <option value="sites">Sites</option>
                        </b-select>
                    </b-field>
                    <b-field label="Légende">
                        <ul v-if="colorsite !== 'sites'">
                            <li v-for="item in legendeitems" :key="item.id">
                                <svg width="30" height="20">
                                    <rect width="30"
                                          height="20"
                                          style="fill-opacity:.75;stroke-width:3;stroke:rgb(0,0,0)"
                                          v-bind:style="{ fill: item.color}"/>
                                </svg>
                                {{ item.lib }}
                            </li>
                        </ul>
                        <span v-if="colorsite === 'sites'">
                        Pas de légende, chaque site a une couleur aléatoire.
                    </span>
                    </b-field>

                </div>

            </b-tab-item>

            <b-tab-item label="Couches SIG">

                <b-field label="Recherche de couches (min. 3 char.)">
                    <b-input
                        v-model="geoserverFilterInput">
                    </b-input>
                </b-field>

                <div class="box" v-for="layer in geoserverLayersFiltered" v-bind:key="layer.name">
                    <b-checkbox v-model=layer.checkboxValue
                                @change.native="addGSlayer(layer)">
                        {{ layer.title }}

                        <b-tag
                            v-for="tag in layer.tags" v-bind:key="tag"
                            type="is-info"
                            style="padding-right: 5px; padding-left: 5px; margin-right: 5px;">
                            {{ tag }}
                        </b-tag>

                        <b-tooltip
                            v-if="layer.abstract !== ''"

                            position="is-left"
                            multilined
                        >
                            <b-icon
                                icon="info-circle"
                                style="padding-left: 10px"
                                size="is-small">
                            </b-icon>
                            <template v-slot:content>
                                <span v-html="layer.abstract"></span>
                            </template>

                        </b-tooltip>
                        <b-icon
                            v-if="layer.isPref"
                            icon="star"
                            size="is-small"
                            style="color: orange; padding-left: 20px"
                        >
                        </b-icon>
                    </b-checkbox>
                    <b-slider v-model=layer.sliderValue :min="1" :max="10" ticks
                              @change="opacityGSLayer(layer)"
                    >
                    </b-slider>
                    <img
                        :src="layer.legendUrl"
                        v-if="layer.checkboxValue"
                    />
                </div>
            </b-tab-item>

            <b-tab-item label="Options">
                <div class="field">
                    <b-switch v-model="popup"
                              @change.native="displayPopup(popup)">
                        Affichage des infos au survol des parcelles
                    </b-switch>
                </div>

                <div class="field">
                    <!--
                    <b-switch v-model="units"
                              @change.native="displayUnits(units)">
                        Afficher la notation simplifiée
                    </b-switch>
                    -->
                </div>
            </b-tab-item>

        </b-tabs>
    </div>
</template>

<script>
    // BUS
    import {bus} from '@/main';
    // VUEX
    import store from './store.js';

    export default {
        name: "MapLayers",
        props: {
            // Get the map mode : sitesgeres || animationfonciere
            mode: String
        },
        computed: {
            geoserverLayersFiltered() {

                const i = this.geoserverFilterInput.toLowerCase();
                if (i.length > 2 ) {
                    return this.geoserverLayers.filter(layer => (layer.name + " " + layer.abstract + " " + Object.values(layer.tags).join(' ')).toLowerCase().includes(i));
                } else {
                    return this.geoserverLayers;
                }
            }
        },
        mounted() {
            // Get user preferences stored in database
            this.getPrefs();
            // Query the GeoServer to fetch public layers
            this.fetchLayers();

        },
        created() {
        },
        methods: {
            storeIt() {
                store.commit('SET_COLOR', this.color);
                store.commit('SET_COLOR_SITE', this.colorsite);
                store.commit('SET_LAYERS', this.geoserverLayers);
                store.commit('SET_POPUP', this.popup);
            },
            async getPrefs() {
                try {
                    const response = await this.$axios.get('prefs')
                    console.log(response.data[0])
                    let layers = JSON.parse(response.data[0].layers);
                    let colorsi =  response.data[0].themecolorsite
                    let color = response.data[0].themecolor
                    let popup = response.data[0].popup;
                    // Store in local browser
                    store.commit('SET_COLOR', color);
                    store.commit('SET_COLOR_SITE', colorsi);
                    store.commit('SET_LAYERS', layers);
                    store.commit('SET_POPUP', popup);

                    // Load data in the component
                    this.popup = popup;

                    this.color = color;
                    this.colorsite = colorsi;

                    if (this.mode === 'animationfonciere') {
                        this.legende(this.color);
                        layers.forEach(l => {
                            this.geoserverLayers.filter(la => la.title === l.title ? la.sliderValue = true : false);
                            this.geoserverLayers.filter(la => la.title === l.title ? la.checkboxValue = true : false);
                            bus.$emit('addGeoserverlayer', l);
                            bus.$emit('changeThemeColor', color);
                            bus.$emit('changePopup', popup);
                        });
                    } else if ( this.mode === 'sitesgeres') {
                        this.legende(this.colorsite);
                        layers.forEach(l => {
                            console.log(layers)
                            console.log(this.geoserverLayers)
                            this.geoserverLayers.filter(la => la.title === l.title ? la.sliderValue = l.sliderValue : null);
                            this.geoserverLayers.filter(la => la.title === l.title ? la.checkboxValue = l.checkboxValue : null);
                            bus.$emit('addGeoserverlayerSites', l);
                            // bus.$emit('changeThemeColor', colorsi);
                            // bus.$emit('changePopup', popup);
                        });
                    }
                } catch (err) {
                    console.log(err)
                }
            },
            displayPopup(popup) {
                // Send rule to map
                bus.$emit('changePopup', popup);
                // Store in RAM
                store.commit('SET_POPUP', popup);
            },
            async savePrefs() {
                try {
                    // Save only the displayed layers
                    const l = this.geoserverLayers.filter(layer => layer.checkboxValue === true);
                    await this.$axios({
                        method: 'POST',
                        url: 'savePrefs',
                        data: {
                            layers: l,
                            themecolor: this.color,
                            themecolorsite: this.colorsite,
                            popup: this.popup
                        }
                    })
                    this.$buefy.snackbar.open(
                        {
                            message : `Préférences enregistrées`,
                            type: 'is-success',
                            position: 'is-bottom-left'
                        });
                    this.storeIt();
                } catch (err) {
                    console.log(err)
                }
            },
            reloadPrefs() {
                this.getPrefs();
            },
            legende(e) {
                // Get color scheme from database
                this.$axios.get('legende?theme=' + e)
                    .then(function (response) {
                        this.legendeitems = response.data;
                    }.bind(this))
                    .catch(error => console.log(error));
            },
            changeThemeColor(e) {
                // Save temp
                this.storeIt();
                // trigger map color change
                if (this.mode === "animationfonciere") {
                    bus.$emit('changeThemeColor', e)
                    this.legende(this.color)
                } else {
                    bus.$emit('changeThemeColorSites')
                    this.legende(this.colorsite)
                }

            },
            async fetchLayers() {
                try {
                    const response = await this.$axios.get(`/geoserver`);
                    this.geoserverLayers = response.data;
                    console.log(this.geoserverLayers)
                } catch (error) {
                    console.error('Error fetching WMS layers:', error);
                }
            },
            addGSlayer(e) {
                this.mode === 'animationfonciere' ?
                bus.$emit('addGeoserverlayer', e) :
                bus.$emit('addGeoserverlayerSites', e)
                this.storeIt();
            },
            opacityGSLayer(e) {
                this.mode === 'animationfonciere' ?
                bus.$emit('opacityGeoserverlayer', e) :
                bus.$emit('opacityGeoserverlayerSites', e)
                this.storeIt();
            }
        },
        data: function () {
            return {
                // Geoserver
                geoserverLayers: [],
                geoserverFilterInput: "",
                // Others
                legendeitems: null,
                color: null,
                colorsite: null,
                popup: null,
            }
        },
    }
</script>

<style scoped>

</style>
