import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    prefs: {
        color: null,
        colorsite: null,
        layers: null,
        popup: null,
        territoire: null,
    },
};

const mutations = {
    SET_COLOR: (state, c) => {
        state.prefs.color = c;
    },
    SET_COLOR_SITE: (state, c) => {
        state.prefs.colorsite = c;
    },
    SET_LAYERS: (state, l) => {
        state.prefs.layers = JSON.parse(JSON.stringify(l));
    },
    SET_POPUP: (state, p) => {
        state.prefs.popup = p;
    },
    SET_TERRITOIRE: (state, p) => {
        state.prefs.territoire = p;
    }
};

const getters = {
    color: state => { return state.prefs.color },
    colorsite: state => { return state.prefs.colorsite },
    layers: state => { return state.prefs.layers },
    popup: state => { return state.prefs.popup },
    territoire: state => { return state.prefs.territoire },
};

const actions = {
};

export default new Vuex.Store({
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
    strict: true,
});