<template>
    <div>
        <h1 class="title">Status et évènements</h1>

        <b-field label="Catégorie">
            <b-select placeholder="Sélectionnez une catégorie">
                <option
                        v-for="option in filter.categories"
                        :value="option.idcategorie"
                        :key="option.idcategorie">
                    {{ option.libcategorie }}
                </option>
            </b-select>
        </b-field>

        <b-field label="Statut">
            <b-select placeholder="Sélectionnez un statut">
                <option
                        v-for="option in filter.status"
                        :value="option.idstatut"
                        :key="option.idstatut">
                    {{ option.libstatut }}
                </option>
            </b-select>
        </b-field>

        <b-field label="Evènement">
            <b-select placeholder="Sélectionnez un évènement">
                <option
                        v-for="option in filter.evenements"
                        :value="option.idevenement"
                        :key="option.idevenement">
                    {{ option.libevenement }}
                </option>
            </b-select>
        </b-field>

    </div>
</template>

<script>
    export default {
        name: "AdvancedSearch",
        mounted() {
            this.getFilter();
        },
        methods: {
            getFilter() {
                this.$axios.get('getfilter')
                    .then(function (response) {
                        this.filter = response.data
                    }.bind(this))
                    .catch(error => console.log(error));
            }
        },
        data: function () {
            return {
                filter: {},
            }
        },
    }
</script>

<style scoped>

</style>