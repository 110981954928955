<template>
    <div id="tabs">

        <div class="block">
            <b-switch v-model="add"
                      :disabled="info || mod"
                      type="is-success"
                      @change.native="reset()">

                Ajouter
            </b-switch>

            <b-switch v-model="info"
                      :disabled="add || mod"
                      type="is-info"
                      @change.native="reset()">

                Infos
            </b-switch>

            <b-switch v-model="mod"
                      :disabled="add || info"
                      type="is-info"
                      @change.native="reset()">

                Modifier
            </b-switch>

        </div>

        <div v-if="activeTab !== 1">
            <div class="card">
                <div class="card-content">
                    <p>
                        <b-icon icon="life-ring"></b-icon>
                        Cliquez sur une parcelle suivie pour lui ajouter un évènement.
                    </p>
                    <p>
                        <b-icon icon="info-circle"></b-icon>
                        Les évènements disponibles à la saisie sont conditionnés
                        à l'évènement le plus récent de la parcelle sélectionnée.
                    </p>
                    <p>
                        <b-icon icon="info-circle"></b-icon>
                        Il n'est pas possible d'indiquer une date anterieure au dernier
                        évènement saisi sur une parcelle.
                    </p>
                </div>
            </div>
            <hr>
        </div>

        <b-tabs type="is-boxed"
                v-model="activeTab"
                v-if="add || info || mod"
        >

            <!-- PARCELLES -->
            <b-tab-item
                    label="Evènement"
                    icon="comment-dots"
                    :visible="activeTab === 1">
                <EventForm
                        v-if="events && events.length > 0"
                        :bypass="bypass"
                        :events="events"
                        :eventsbypass="eventsbypass"
                        :lastdate="lastdate"
                        :idparcelle="idparcelle"
                        :add="add"
                        :oldPostEvent="postEvent"
                        :existinglot="existinglot"
                        :pebIdToMod="pebIdToMod">
                </EventForm>

                <div v-if="events && events.length == 0">
                    <p>
                        Impossible d'ajouter un nouvel évènement
                    </p>



                </div>
            </b-tab-item>
        </b-tabs>

        <b-modal
                :active.sync="isComponentModalActive"
                has-modal-card
                full-screen
                :can-cancel="true">

            <!-- Fullpage modal to display table -->
            <ModalTableIndividuel
                    :eventsinfo="eventsinfo">
            </ModalTableIndividuel>

        </b-modal>

    </div>

</template>

<script>
    import {bus} from '../main'

    import EventForm from "./EventForm";
    import ModalTableIndividuel from "./ModalTableIndividuel";

    export default {
        name: "ManageEvent",
        components: {ModalTableIndividuel, EventForm},
        mounted() {
            this.reset();
        },
        computed: {},
        beforeDestroy() {
            // Reset all highlight on leave
            this.reset();
            bus.$off('selectedPlotToModEvent'); // Eviter les conflits avec la partie parcelle
        },
        created() {
            bus.$on('selectedPlotToModEvent', (e) => {
                console.log('calling events functions')
                ///////////////////////////////////////////////////
                //                Add event
                ///////////////////////////////////////////////////
                if (this.add) {
                    this.postEvent = {};
                    ///////////////////////////////////////////////////
                    //                If first click
                    ///////////////////////////////////////////////////
                    if (this.idparcelle.length === 0) {
                        // Set master event
                        this.masterevent = e.idevenement
                        // Get possible events
                        this.$axios.get(`possibleevents?parent=${e.idevenement}`)
                            .then(function (response) {
                                this.events = response.data.events;
                                this.eventsbypass = response.data.bypass;
                                this.bypass = response.data.pivot.pivot
                                this.activeTab = 1;
                            }.bind(this))
                            .catch(error => console.log(error));
                        // Reset date array
                        this.lastdateArr = [];
                    }
                    ///////////////////////////////////////////////////
                    //                Append if same event and not clicked
                    ///////////////////////////////////////////////////
                    if (this.masterevent === e.idevenement && !this.idparcelle.includes(e.idparcelle)) {
                        // Append id parcelle
                        this.idparcelle.push(e.idparcelle);
                        // Append last event date
                        this.lastdateArr.push({'parcelle': e.idparcelle, 'date': new Date(e.dateevenement)});
                        // Get the more recent date in the array of object
                        if (this.lastdateArr.length === 1) {
                            this.lastdate = this.lastdateArr[0].date
                        } else {
                            this.lastdate = this.lastdateArr[0].date
                            this.lastdateArr.forEach(p => {
                                this.lastdate = (p.date > this.lastdate) ? p.date : this.lastdate;
                            });
                        }

                        // if lot
                        if (e.idlot) {
                            this.existinglot = e.idlot;
                            this.$axios.get(`parcellesLot?idlot=${e.idlot}`)
                                .then(response => {
                                    let lots = response.data;
                                    this.idparcelle = [];
                                    lots.forEach(i => {
                                        this.idparcelle.push(i.idparcelle);
                                        // Set highlight color
                                        bus.$emit('fillPlot', i.idparcelle, true);
                                    })
                                }).catch(error => console.log(error));
                        } else {
                            // Set highlight color
                            bus.$emit('fillPlot', e.idparcelle, true);
                        }

                    ///////////////////////////////////////////////////
                    //                Or remove if clicked
                    ///////////////////////////////////////////////////
                    } else if (this.masterevent === e.idevenement) {

                        // If lot, remove all
                        if (e.idlot) {
                            this.$axios.get(`parcellesLot?idlot=${e.idlot}`)
                                .then(response => {
                                    let lots = response.data;
                                    lots.forEach(i => {
                                        bus.$emit('fillPlot', i.idparcelle, false);
                                    })
                                    this.reset();
                                }).catch(error => console.log(error));
                        } else {
                            // Set highlight color
                            // Remove idparcelle from array
                            this.idparcelle = this.idparcelle.filter(item => item !== e.idparcelle)
                            // Remove also date for this plot
                            // Get the more recent date in the array of object
                            this.lastdateArr = this.lastdateArr.filter(item => item.parcelle !== e.idparcelle)
                            // Get the more recent date in the array of object
                            if (!this.lastdateArr.length) {
                                this.reset();
                            } else if (this.lastdateArr.length === 1) {
                                this.lastdate = this.lastdateArr[0].date
                            } else {
                                this.lastdate = this.lastdateArr[0].date
                                this.lastdateArr.forEach(p => {
                                    this.lastdate = (p.date > this.lastdate) ? p.date : this.lastdate;
                                });
                            }
                            // Remove highlight color
                            bus.$emit('fillPlot', e.idparcelle, false);
                        }
                    ///////////////////////////////////////////////////
                    //                Not possible
                    ///////////////////////////////////////////////////
                    } else {
                        this.$buefy.snackbar.open(
                            {
                                message: `Action groupée impossible`,
                                type: 'is-danger',
                                position: 'is-bottom-left'
                            });
                    }
                ///////////////////////////////////////////////////
                //                Get info
                // /////////////////////////////////////////////////
                } else if (this.info) {
                    this.postEvent = {};
                    // get parcel info
                    this.$axios.get('getStoredParcelle?id=' + e.idparcelle)
                        .then(function (response) {
                            console.log(response.data[0]);
                            this.eventsinfo = response.data[0];
                            this.isComponentModalActive = true;
                        }.bind(this))
                        .catch(error => console.log(error));
                ///////////////////////////////////////////////////
                //                Make modification
                // /////////////////////////////////////////////////
                } else if (this.mod && !e.idlot) {
                    // Reset form
                    this.postEvent = {};
                    if (this.idparcelle.length === 0) {
                        // Set master N-1 event
                        this.masterevent = e.p_idevenement;
                        let url = this.masterevent ? 'possibleevents?parent=' + e.p_idevenement : 'possibleevents';
                        this.$axios.get(url)
                            .then(function (response) {
                                this.events = response.data.events;
                                this.eventsbypass = response.data.bypass;
                                this.bypass = response.data.pivot.pivot
                                this.activeTab = 1;
                            }.bind(this))
                            .catch(error => console.log(error));
                        // Reset date array
                        this.lastdateArr = [];
                    }
                    // Check if it's the same event and append
                    if (this.masterevent === e.p_idevenement && !this.idparcelle.includes(e.idparcelle)) {
                        // Append id parcelle
                        this.idparcelle.push(e.idparcelle);
                        // Append idparcelle_evenement id to mod
                        this.pebIdToMod.push(e.idparcelle_evenement);
                        // Append last event date
                        this.lastdateArr.push({'parcelle': e.idparcelle, 'date': new Date(e.p_dateevenement), 'comment': e.rqevenement});
                        // Get the more recent date in the array of object
                        if (this.lastdateArr.length === 1) {
                            this.lastdate = this.lastdateArr[0].date
                            this.postEvent.comment = e.rqevenement;
                            let d = new Date(e.dateevenement);
                            this.postEvent.date = d;
                            this.postEvent.evenement = e.idevenement;
                        } else {
                            this.lastdate = this.lastdateArr[0].date
                            this.lastdateArr.forEach(p => {
                                this.lastdate = (p.date > this.lastdate) ? p.date : this.lastdate;
                                this.postEvent.comment += p.parcelle + " : " + this.lastdateArr[0].comment
                            });
                        }
                        // Set highlight color
                        bus.$emit('fillPlot', e.idparcelle, true);
                        // Or remove
                    } else if (this.masterevent === e.p_idevenement) {
                        // Remove idparcelle from array
                        this.idparcelle = this.idparcelle.filter(item => item !== e.idparcelle)
                        this.pebIdToMod = this.pebIdToMod.filter(item => item !== e.idparcelle_evenement)
                        // Remove also date for this plot
                        // Get the more recent date in the array of object
                        this.lastdateArr = this.lastdateArr.filter(item => item.parcelle !== e.idparcelle)
                        // Get the more recent date in the array of object
                        if (!this.lastdateArr.length) {
                            this.reset();
                        } else if (this.lastdateArr.length === 1) {
                            this.lastdate = this.lastdateArr[0].date
                            this.postEvent.comment = this.lastdateArr[0].parcelle + " : " + this.lastdateArr[0].comment
                        } else {
                            this.lastdate = this.lastdateArr[0].date
                            this.postEvent.comment = "";
                            this.lastdateArr.forEach(p => {
                                this.lastdate = (p.date > this.lastdate) ? p.date : this.lastdate;
                                this.postEvent.comment += p.parcelle + " : " + this.lastdateArr[0].comment
                            });
                        }
                        // Remove highlight color
                        bus.$emit('fillPlot', e.idparcelle, false);
                    } else {
                        this.$buefy.snackbar.open(
                            {
                                message: `Action groupée impossible`,
                                type: 'is-danger',
                                position: 'is-bottom-left'
                            });
                    }
                } else if (this.mod && e.idlot) { alert('Pas possible de modifier les lots pour le moment.'); }
            });

            ///////////////////////////////////////////////////
            //                Reset avec save
            // /////////////////////////////////////////////////
            bus.$on('resetEvent', (e, f) => {
                if (e === 'enough') {
                    this.idparcelle.forEach(p => {
                        bus.$emit('fillPlot', p, false);
                    });
                    this.idparcelle = [];
                    this.lastdate = null;
                    this.lastdateArr = [];
                    this.events = null;
                    this.activeTab = 0;
                } else  {
                    // Keep the last insert event as masterevent for the new form
                    this.masterevent = e;
                    this.$axios.get(`possibleevents?parent=${e}`)
                        .then(function (response) {
                            this.events = response.data.events;
                            this.eventsbypass = response.data.bypass;
                            this.bypass = response.data.pivot.pivot
                            // Update last date event for all parcelles
                            this.lastdateArr.forEach(p => {
                                // TODO
                                p.date = f;
                            });
                            this.lastdate = f;
                            console.log(`last date ${f}`)
                        }.bind(this))
                        .catch(error => console.log(error));
                }
            });
        },
        methods: {
            reset() {
                this.idparcelle.forEach(p => {
                    bus.$emit('fillPlot', p, false);
                });
                this.idparcelle = [];
                this.lastdate = null;
                this.lastdateArr = [];
                this.events = null;
                this.activeTab = 0;
                this.existinglot = null;
                this.pebIdToMod = [];
            }
        },
        data: function () {
            return {
                bypass: null,
                activeTab: 0,
                eventsbypass: null,
                events: null,
                idparcelle: [],
                masterevent: null,
                lastdate: null,
                lastdateArr: [],
                info: false,
                add: false,
                mod: false,
                postEvent: {},
                // Info
                isComponentModalActive: false,
                // Pass data to child component
                eventsinfo: [],

                existinglot: null,

                pebIdToMod: [],
            }
        },
    }
</script>

<style scoped>

</style>
