<template>
    <div id="layers">
        <h1 class="title">Recherche géographique</h1>

        <b-field label="Coordonnées" horizontal>
            <b-input v-model="coor.x" placeholder="0.2"></b-input>
            <b-input v-model="coor.y" placeholder="45"></b-input>
            <b-button type="is-success"
                      icon-right="search"
                      @click="coordZoom()"
            />
        </b-field>

        <b-tabs>
            <b-tab-item label="Entités CEN">
                <!-- Search by aire foncière -->
                <b-field label="Aire foncière">
                    <b-autocomplete
                            v-model="reduceAires"
                            placeholder="Recherche"
                            keep-first
                            open-on-focus
                            clearable
                            icon="search"
                            :data="getFilteredAires"
                            field="lib"
                            @select="option => boundsAire(option)"
                            >
                    </b-autocomplete>
                </b-field>
                <!-- Search by site CEN -->
                <b-field label="Site géré">
                    <b-autocomplete
                            v-model="reduceAiresSCEN"
                            placeholder="Recherche"
                            keep-first
                            open-on-focus
                            clearable
                            icon="search"
                            :data="getFilteredAiresSCEN"
                            field="lib"
                            @select="option => boundsSiteCEN(option)"
                            >
                    </b-autocomplete>
                </b-field>
            </b-tab-item>

            <b-tab-item label="Entités admin.">
                <b-field label="Département">
                    <b-select placeholder="Choisir..."
                              icon="draw-polygon"
                              v-model="departement"
                              @input="getCommunes">
                        <option
                                v-for="option in departements"
                                :value="option.iddepartement"
                                :key="option.iddepartement">
                            {{ option.iddepartement }} - {{ option.nom }}
                        </option>
                    </b-select>
                </b-field>
                <b-field
                        label="Commune"
                        v-if="departement">
                    <b-select placeholder="Choisir..."
                              v-model="commune"
                              @input="getSections"
                              icon="draw-polygon">
                        <option
                                v-for="option in communes"
                                :value="option.idcommune"
                                :key="option.idcommune">
                            {{ option.nom }}
                        </option>
                    </b-select>
                </b-field>
                <b-field
                        label="Section"
                        v-if="commune">
                    <b-select placeholder="Choisir..."
                              v-model="section"
                              @input="getParcelles"
                              icon="draw-polygon">
                        <option
                                v-for="option in sections"
                                :value="option.idsection"
                                :key="option.idsection">
                            {{ option.code }}
                        </option>
                    </b-select>
                </b-field>
                <b-field
                        label="Parcelles"
                        v-if="section">
                    <b-select placeholder="Choisir..."
                              v-model="parcelle"
                              icon="draw-polygon"
                              @input="boundsParcelle">
                        <option
                                v-for="option in parcelles"
                                :value="option.idparcelle"
                                :key="option.idparcelle">
                            {{ option.numero }} ({{ option.idparcelle }})
                        </option>
                    </b-select>
                </b-field>
            </b-tab-item>
            <b-tab-item label="ID parcelle">
                <b-field label="Département">
                    <b-select placeholder="Choisir..."
                              icon="draw-polygon"
                              v-model="departement"
                              @input="getCommunes">
                        <option
                                v-for="option in departements"
                                :value="option.iddepartement"
                                :key="option.iddepartement">
                            {{ option.iddepartement }} - {{ option.nom }}
                        </option>
                    </b-select>
                </b-field>
                <b-field label="Chercher une parcelle"
                         v-if="departement">
                    Mininum 5 caractères.
                    <b-autocomplete
                            :data="data"
                            placeholder="par ex. 0B0444"
                            field="title"
                            :loading="isFetching"
                            @typing="getAsyncData"
                            @select="option => selected = option">
                        <template slot-scope="props">
                            {{ props.option.idparcelle }}
                        </template>
                    </b-autocomplete>
                </b-field>
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
    import {bus} from '../main';

    export default {
        name: "GeomFilter",
        props: {
            // Get the map mode : sitesgeres || animationfonciere
            mode: String
        },
        watch: {
            selected: {
                immediate: true,
                handler(newVal) {
                    if (newVal.idparcelle) {
                        this.boundsParcelle(newVal.idparcelle);
                        this.data = [];
                    }
                },
            },
        },
        computed: {
            // Search filter on Aires
            getFilteredAires() {
                return this.aires.filter((option) => {
                    return option.lib
                        .toString()
                        .toLowerCase()
                        .indexOf(this.reduceAires.toLowerCase()) >= 0
                })
            },
            getFilteredAiresSCEN() {
                return this.airesSCEN.filter((option) => {
                    return option.lib
                        .toString()
                        .toLowerCase()
                        .indexOf(this.reduceAiresSCEN.toLowerCase()) >= 0
                })
            },
        },
        mounted() {
            this.getDepartements();
            this.getAires();
        },
        created() {
        },
        methods: {
            coordZoom: function() {
                this.mode === "animationfonciere" ?
                bus.$emit('newSpot',this.coor.x, this.coor.y) :
                bus.$emit('newSpotSites',this.coor.x, this.coor.y)

            },
            getAsyncData: function (name) {
                if (!name.length) {
                    this.data = []
                    return
                }
                if (name.length > 5) {
                    this.isFetching = true
                    this.$axios.get(`idparcelles?departement=${this.departement}&like=${name}`)
                        .then(({data}) => {
                            this.data = []
                            data.forEach((item) => this.data.push(item))
                        })
                        .catch((error) => {
                            this.data = []
                            throw error
                        })
                        .finally(() => {
                            this.isFetching = false
                        })
                }
            },
            getDepartements() {
                this.$axios.get('departements')
                    .then(function (response) {
                        let toSort = response.data;
                        this.departements = toSort.slice(0);
                        this.departements.sort(function (a, b) {
                            let x = a.nom.toLowerCase();
                            let y = b.nom.toLowerCase();
                            return x < y ? -1 : x > y ? 1 : 0;
                        });
                    }.bind(this))
                    .catch(error => console.log(error));
            },
            getAires() {
                this.departements = null;
                this.departement = null;
                this.communes = null;
                this.commune = null;
                this.sections = null;
                this.section = null;
                this.parcelles = null;
                this.parcelle = null;
                this.$axios.get('aires')
                    .then(function (response) {
                        this.aires = response.data
                    }.bind(this))
                    .catch(error => console.log(error));
                this.$axios.get('airesSCEN')
                    .then(function (response) {
                        this.airesSCEN = response.data
                    }.bind(this))
                    .catch(error => console.log(error));
            },
            getCommunes: function (e) {

                this.$axios.get('communes?departement=' + e)
                    .then(function (response) {
                        this.commune = null;
                        this.sections = null;
                        this.section = null;
                        this.parcelles = null;
                        this.parcelle = null;
                        let toSort = response.data.communes;
                        this.communes = toSort.slice(0);
                        this.communes.sort(function (a, b) {
                            let x = a.nom.toLowerCase();
                            let y = b.nom.toLowerCase();
                            return x < y ? -1 : x > y ? 1 : 0;
                        });
                        this.mode === "animationfonciere" ?
                        bus.$emit('newBounds', response.data.bounds[0].geom) :
                        bus.$emit('newBoundsSites', response.data.bounds[0].geom)
                    }.bind(this))
                    .catch(error => console.log(error));
            },
            getSections: function (e) {
                this.$axios.get('sections?commune=' + e)
                    .then(function (response) {
                        this.section = null;
                        this.parcelles = null;
                        this.parcelle = null;
                        this.sections = response.data.sections;
                        this.mode === "animationfonciere" ?
                        bus.$emit('newBounds', response.data.bounds[0].geom) :
                        bus.$emit('newBoundsSites', response.data.bounds[0].geom)

                    }.bind(this))
                    .catch(error => console.log(error));
            },
            getParcelles: function (e) {
                this.$axios.get('parcelles?section=' + e)
                    .then(function (response) {
                        this.parcelle = null;
                        let toSort = response.data.parcelles;
                        this.parcelles = toSort.slice(0);
                        this.parcelles.sort(function (a, b) {
                            let x = parseInt(a.numero);
                            let y = parseInt(b.numero);
                            return x < y ? -1 : x > y ? 1 : 0;
                        });
                        this.mode === "animationfonciere" ?
                        bus.$emit('newBounds', response.data.bounds[0].geom) :
                        bus.$emit('newBoundsSites', response.data.bounds[0].geom)
                    }.bind(this))
                    .catch(error => console.log(error));
            },
            boundsParcelle: function (e) {
                this.$axios.get('boundsparcelle?id=' + e)
                    .then(function (response) {
                        this.mode === "animationfonciere" ?
                        bus.$emit('newBounds', response.data[0].geom) :
                        bus.$emit('newBoundsSites', response.data[0].geom)
                    }.bind(this))
                    .catch(error => console.log(error));
            },
            boundsAire(option) {
                if (option) {
                    this.reduceAiresSCEN = ''; // Reset the other field
                    this.$axios.get('boundsaire?id=' + option.idaire)
                        .then(function (response) {
                            this.mode === "animationfonciere" ?
                            bus.$emit('newBounds', response.data[0].geom):
                            bus.$emit('newBoundsSites', response.data[0].geom)

                        }.bind(this))
                        .catch(error => console.log(error));
                }
            },
            boundsSiteCEN(option) {
                if (option) {
                    this.reduceAires = ''; // Reset the other field
                    this.$axios.get('boundssitecen?id=' + option.idsitecen)
                        .then(function (response) {
                            this.mode === "animationfonciere" ?
                            bus.$emit('newBounds', response.data[0].geom) :
                            bus.$emit('newBoundsSites', response.data[0].geom)
                        }.bind(this))
                        .catch(error => console.log(error));
                }
            },

        },
        data: function () {
            return {
                // Coordinate
                coor: {x: null, y: null},
                // Classic Select
                departements: null,
                departement: null,
                communes: null,
                commune: null,
                sections: null,
                section: null,
                parcelles: null,
                parcelle: null,
                // Aires
                aires: [],                  // All aires from db
                reduceAires: '',          // Filter from input text
                // Sites CEN
                airesSCEN: [],                  // All aires from db
                reduceAiresSCEN: '',          // Filter from input text
                // Search by idparcelle
                data: [],
                selected: {},
                isFetching: false

            }
        },
    }
</script>

<style scoped>

</style>
