<template>
    <b-navbar>
        <template slot="brand">
            <b-navbar-item>
                <a href="/">
                    <img
                        src="@/assets/img/logo.png"
                        alt="Logo">
                </a>
            </b-navbar-item>
        </template>
        <template slot="start">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                Accueil
            </b-navbar-item>

            <b-navbar-item
                tag="router-link"
                :to="{ path: '/ajouter' }"
                v-if="logged">
                Animation foncière
            </b-navbar-item>

            <b-navbar-item
                tag="router-link"
                :to="{ path: '/sites' }"
                v-if="logged">
                Sites gérés
            </b-navbar-item>

            <!--
            <b-navbar-item
                tag="router-link"
                :to="{ path: '/animation' }"
                v-if="logged">
                Export [WIP]
            </b-navbar-item>
            -->

            <b-navbar-item
                tag="router-link"
                :to="{ path: '/dashboard'}"
                v-if="logged">
                Tableaux de bord
            </b-navbar-item>


            <b-navbar-dropdown label="Aide"
                               v-if="logged">
                <b-navbar-item
                    tag="router-link"
                    :to="{ path: '/aide' }"
                    v-if="logged">
                    Guide d'utilisation
                </b-navbar-item>
                <b-navbar-item
                    tag="router-link"
                    :to="{ path: '/logigrammes' }"
                    v-if="logged">
                    Logigrammes
                </b-navbar-item>
            </b-navbar-dropdown>

            <b-navbar-item
                tag="router-link"
                :to="{ path: '/apropos' }">
                Mentions Légales
            </b-navbar-item>

        </template>
        <template slot="end">
            <b-navbar-item tag="div">

                <section>

                    <button
                        v-if="!logged"
                        class="button is-primary"
                    >
                        <a href="https://foncier.cen-nouvelle-aquitaine.dev/api/api/login">Connexion</a>
                    </button>
                    <span
                        v-if="logged"
                    >
                        <b-icon
                            icon="user"
                        ></b-icon>
                        {{ identity.firstname + " " + identity.lastname }}
                        <b-icon
                            :icon="identity.acte_engagement ? 'check-square' : 'times-circle'"
                            :type="identity.acte_engagement ? 'is-success' : 'is-danger'"
                        ></b-icon>
                        <b-icon
                            :icon="identity.access_ff ? 'check-square' : 'times-circle'"
                            :type="identity.access_ff ? 'is-success' : 'is-danger'"
                        ></b-icon>
                    </span>

                    <!--
                    <form @submit.prevent="login"
                          v-if="!logged">
                        <b-field>
                            <b-field>
                                <b-input
                                        type="email"
                                        v-model="email"
                                        placeholder="Votre courriel"
                                        required>
                                </b-input>
                            </b-field>
                            <b-field>
                                <b-input
                                        type="password"
                                        v-model="password"
                                        password-reveal
                                        placeholder="Votre mot de passe"
                                        required>
                                </b-input>
                            </b-field>
                            <button class="button is-primary"
                                    native-type="submit">
                                Se connecter
                            </button>
                        </b-field>
                    </form>
                    -->
                    <!--
                    <button class="button is-primary"
                            v-if="logged"
                            @click="logout">
                        {{ identity }} - Déconnexion
                    </button>
                    -->

                </section>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>


import {bus} from "../main";

export default {
    name: "NavBar",
    components: {},
    mounted() {
        this.whoami();
    },
    created() {
        bus.$on('killsession', (e) => {
            if (e) {
                this.logout();
            }
        });
    },
    methods: {
        logout() {
            this.logged = false;
            this.$router.push("/") // Back to home
        },
        whoami() {
            // Check is users have a valid token
            this.$axios.get('me')
                .then((r) => {
                    this.identity = r.data
                    this.logged = true;
                })
                .catch((err) => {
                    console.log(err.response.data)
                });
        },
    },
    data() { /* Data properties will go here */
        return {
            logged: false,
            password: null,
            email: null,
            identity: null,
        }
    }
}
</script>

<style scoped>

</style>
