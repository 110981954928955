<template>
    <div>
        <div class="modal-card" style="width: auto; padding: 30px">
            <!--
            ██╗  ██╗███████╗ █████╗ ██████╗ ███████╗██████╗
            ██║  ██║██╔════╝██╔══██╗██╔══██╗██╔════╝██╔══██╗
            ███████║█████╗  ███████║██║  ██║█████╗  ██████╔╝
            ██╔══██║██╔══╝  ██╔══██║██║  ██║██╔══╝  ██╔══██╗
            ██║  ██║███████╗██║  ██║██████╔╝███████╗██║  ██║
            ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚═╝  ╚═╝
            -->
            <header class="modal-card-head">
                <p class="modal-card-title">Informations sur le propriétaire</p>
                <div class="buttons">

                    <b-button type="is-success"
                              @click="$parent.close()">
                        Fermer
                    </b-button>
                </div>

            </header>
            <!--
            ███████╗██╗   ██╗███████╗███╗   ██╗████████╗███████╗    ██╗     ██╗███████╗████████╗
            ██╔════╝██║   ██║██╔════╝████╗  ██║╚══██╔══╝██╔════╝    ██║     ██║██╔════╝╚══██╔══╝
            █████╗  ██║   ██║█████╗  ██╔██╗ ██║   ██║   ███████╗    ██║     ██║███████╗   ██║
            ██╔══╝  ╚██╗ ██╔╝██╔══╝  ██║╚██╗██║   ██║   ╚════██║    ██║     ██║╚════██║   ██║
            ███████╗ ╚████╔╝ ███████╗██║ ╚████║   ██║   ███████║    ███████╗██║███████║   ██║
            ╚══════╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝    ╚══════╝╚═╝╚══════╝   ╚═╝
            -->
            <section class="modal-card-body">

                <div class="columns">

                    <!-- Left column, scroll with table -->
                    <div class="column">

                        <section>
                            <b-message title="Information importante" :active.sync="isActive"
                                       aria-close-label="Close message">
                                <p>La couche de parcelles présentée dans cette application date du <b>{{ md.millesime_cadastre}}</b>.<br>
                                    La seule source d'information disponible pour les données de propriétaires dont le
                                    CEN NA dispose est les <b>Fichiers Fonciers CEREMA du {{ md.millesime_ff }}</b>.<br>
                                    Pour vos besoins d'animation foncière nous avons fait une requête permettant de
                                    mettre en relation ces deux sources de données.
                                    Les informations données par l'application sont donc à prendre avec précautions car
                                    entre ces deux millésimes des transactions, redécoupages de parcelles,
                                    fusions de communes, renumérotation de parcelles (ou autres évènements mettant à mal
                                    cette relation) ont pu avoir lieu.
                                    Par ailleurs la requête permettant la mise en relation de ces deux sources de
                                    données ne prend pas en compte les propriétés divisées en lot (copropriétés...)
                                </p>
                                <p>
                                    La géométrie des parcelles issue de la base de données des Fichiers Fonciers du {{ md.millesime_ff }} se
                                    base sur des sources multiples (BD parcellaires 2016, 2017, DGFIP 2019...), il est
                                    donc
                                    normal d'apercevoir de petits décalages avec la couche en traits noirs qui elle est
                                    basée sur le millésime DGFIP du {{ md.millesime_cadastre }}).
                                </p>
                            </b-message>
                            <hr>
                        </section>

                        <div class="buttons">
                            <b-button
                                    type="is-info"
                                    icon-left="arrow-left"
                                    @click="previous()">
                                Précédent
                            </b-button>
                            {{ p+1 }} / {{ ownerInfo.length }}
                            <b-button
                                    type="is-info"
                                    icon-right="arrow-right"
                                    @click="next()">
                                Suivant
                            </b-button>

                            <b-button
                                    type="is-info"
                                    icon-left="address-card"
                                    @click="goPagesBlanches()">
                                Voir sur les Pages Blanches
                            </b-button>
                        </div>

                        <hr>

                        <table class="table is-bordered is-striped">
                            <tbody>
                            <tr>
                                <td><b>Code du droit réel ou particulier</b></td>
                                <td>{{ownerInfo[p].ccodrotxt}}</td>
                            </tr>
                            <tr>
                                <td><b>Dénomination personne physique ou morale</b></td>
                                <td> {{ownerInfo[p].ddenom}}</td>
                            </tr>
                            <tr>
                                <td><b>Contenance</b></td>
                                <td> {{ownerInfo[p].dcntpa}}</td>
                            </tr>
                            <tr>
                                <td><b>Date de l'acte</b></td>
                                <td> {{ownerInfo[p].jdatatv}}</td>
                            </tr>
                            <tr>
                                <td><b>Identifiant de compte communal</b></td>
                                <td> {{ownerInfo[p].idprocpte}}</td>
                            </tr>
                            <tr>
                                <td><b>Identifiant de personne départementalisé MAJIC3</b></td>
                                <td> {{ownerInfo[p].idpersonne}}</td>
                            </tr>
                            <tr>
                                <td><b>Code du droit réel ou particulier</b></td>
                                <td> {{ownerInfo[p].ccodrotxt}}</td>
                            </tr>
                            <tr>
                                <td><b>P = propriétaire, G = gestionnaire</b></td>
                                <td> {{ownerInfo[p].typedroit}}</td>
                            </tr>
                            <tr>
                                <td><b>Code démembrement indivision</b></td>
                                <td> {{ownerInfo[p].ccodemtxt}}</td>
                            </tr>
                            <tr>
                                <td><b>Nom de jeune fille</b></td>
                                <td> {{ownerInfo[p].dnomlp}}</td>
                            </tr>
                            <tr>
                                <td><b>Nom d'usage</b></td>
                                <td> {{ownerInfo[p].dnomus}}</td>
                            </tr>
                            <tr>
                                <td><b>Prénom</b></td>
                                <td> {{ownerInfo[p].dprnlp}}</td>
                            </tr>
                            <tr>
                                <td><b>Date de naissance</b></td>
                                <td> {{ownerInfo[p].jdatnss}}</td>
                            </tr>
                            <tr>
                                <td><b>Lieu de naissance</b></td>
                                <td> {{ownerInfo[p].dldnss}}</td>
                            </tr>
                            <tr>
                                <td><b>Forme juridique</b></td>
                                <td> {{ownerInfo[p].dformjur}}</td>
                            </tr>
                            <tr>
                                <td><b>Adresse ligne 3</b></td>
                                <td> {{ownerInfo[p].dlign3}}</td>
                            </tr>
                            <tr>
                                <td><b>Adresse ligne 4</b></td>
                                <td> {{ownerInfo[p].dlign4}}</td>
                            </tr>
                            <tr>
                                <td><b>Adresse ligne 5</b></td>
                                <td> {{ownerInfo[p].dlign5}}</td>
                            </tr>
                            <tr>
                                <td><b>Adresse ligne 6</b></td>
                                <td> {{ownerInfo[p].dlign6}}</td>
                            </tr>
                            </tbody>
                        </table>


                        <hr>
                        <p><b>Ensemble des parcelles liées à l'identifiant personne : {{ownerInfo[p].idpersonne}}</b>
                        </p><br>

                        <table class="table is-bordered">
                            <tbody>
                            <tr>
                                <td><b>Parcelle</b></td>
                                <td><b>Commune</b></td>
                                <td><b>Surface</b></td>
                                <td><b>Type</b></td>
                                <td><b>Bâti</b></td>
                                <td><b>Nb. de bât.</b></td>
                            </tr>

                            <!-- Set row color with bat or original clicked parcel -->
                            <tr v-for="parcel in otherParcelsFilter" :key="parcel.idpar"
                                v-bind:class="[parcel.idpar === ownerInfo[0].idpar ? 'original' : parcel.nbat > 0 ? 'bat' : '' ]">
                                <td v-on:click="zoomto(parcel.geompar)"><a> {{parcel.idpar}} </a></td>
                                <td> {{parcel.idcomtxt}}</td>
                                <td> {{contenanceToSurface(parcel.dcntpa)}}</td>
                                <td> {{parcel.cgrnumdtxt}}</td>
                                <td> {{parcel.tlocdomin}}</td>
                                <td> {{parcel.nbat}}</td>
                            </tr>
                            <tr>
                                <td><b>Surface totale</b></td>
                                <td> {{contenanceToSurface(otherParcelsSum)}}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                            </tbody>
                        </table>
                    </div>

                    <!-- Right column, fixed with map -->
                    <div class="column">
                        <div class="fixedmap">
                            <p><b>Ensemble des parcelles liées à l'identifiant personne :
                                {{ownerInfo[p].idpersonne}}</b>
                            </p><br>

                            <div id="app">
                                <div id="minimap">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!--
            ███████╗ ██████╗  ██████╗ ████████╗███████╗██████╗
            ██╔════╝██╔═══██╗██╔═══██╗╚══██╔══╝██╔════╝██╔══██╗
            █████╗  ██║   ██║██║   ██║   ██║   █████╗  ██████╔╝
            ██╔══╝  ██║   ██║██║   ██║   ██║   ██╔══╝  ██╔══██╗
            ██║     ╚██████╔╝╚██████╔╝   ██║   ███████╗██║  ██║
            ╚═╝      ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝╚═╝  ╚═╝
            -->
            <footer class="modal-card-foot">
                <p><b>Référentiel :</b> Fichiers fonciers retraités par le Cerema – source DGFiP DGALN </p>
            </footer>
        </div>
    </div>
</template>

<script>

    import L from 'leaflet';
    import {bus} from "@/main";

    export default {
        name: "ModalOwner",
        props: ['ownerInfo', 'otherParcels', 'xy'],
        computed: {},
        mounted() {
            this.initMap();
            this.metadata();
        },
        watch: {
            otherParcels: [{
                handler: 'createMiniMap'
            }]
        },
        methods: {
            async metadata() {
                try {
                    const r = await this.$axios.get('getMetadata')
                    console.log(r.data[0])
                    this.md = r.data[0]
                } catch (err) {
                    console.log(err)
                }
            },
            zoomto(geom) {
                var tempLayer = L.geoJson(JSON.parse(geom));
                this.map.fitBounds(tempLayer.getBounds());
            },
            goPagesBlanches() {
                const loc = this.ownerInfo[this.p].dlign6.replace(/\s/g, '+');
                let name;
                if (this.ownerInfo[this.p].dnomus !== null) {
                    name = this.ownerInfo[this.p].dnomus;
                } else {
                    name = this.ownerInfo[this.p].ddenom;
                }
                // console.log(name)
                const url = `https://www.pagesjaunes.fr/pagesblanches/recherche?quoiqui=${name}&ou=${loc}`
                window.open(url, '_blank');
            },
            next() {
                if (this.p < (this.ownerInfo.length - 1)) {
                    this.p = this.p + 1
                    this.map.removeLayer(this.MiniMapLayer);
                    bus.$emit('idpersonne', this.ownerInfo[this.p].idpersonne)
                }
            },
            previous() {
                if (this.p > 0) {
                    this.p = this.p - 1
                    this.map.removeLayer(this.MiniMapLayer);
                    bus.$emit('idpersonne', this.ownerInfo[this.p].idpersonne)
                }
            },
            initMap() {
                // Map declaration
                this.map = L.map('minimap').setView([45.05, 0.6167], 8);

                // Base layers
                let osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 100,
                    attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    zIndex: 1,
                });
                // IGN layers
                const m1 = L.tileLayer(
                    "https://wxs.ign.fr/ortho/geoportail/wmts?SERVICE=WMTS&VERSION=1.0.0" +
                    "&REQUEST=GetTile" +
                    "&STYLE=normal" +
                    "&TILEMATRIXSET=PM" +
                    "&FORMAT=image/jpeg"+
                    "&LAYER=ORTHOIMAGERY.ORTHOPHOTOS"+
                    "&TILEMATRIX={z}" +
                    "&TILEROW={y}" +
                    "&TILECOL={x}",
                    {
                        attribution: 'Fond cartographique &copy; IGN - Données et intégration &copy; CEN Nouvelle-Aquitaine',
                        zIndex: 1,
                        maxZoom: 100,
                    });
                const m6 = L.tileLayer(
                    "https://wxs.ign.fr/ortho/geoportail/wmts?SERVICE=WMTS&VERSION=1.0.0" +
                    "&REQUEST=GetTile" +
                    "&STYLE=normal" +
                    "&TILEMATRIXSET=PM" +
                    "&FORMAT=image/jpeg"+
                    "&LAYER=ORTHOIMAGERY.ORTHOPHOTOS.IRC"+
                    "&TILEMATRIX={z}" +
                    "&TILEROW={y}" +
                    "&TILECOL={x}",
                    {
                        attribution: 'Fond cartographique &copy; IGN - Données et intégration &copy; CEN Nouvelle-Aquitaine',
                        zIndex: 1,
                        maxZoom: 100,
                    });

                const m3 = L.tileLayer("https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
                    {
                        maxZoom: 100,
                        attribution: '&copy; Openstreetmap France | &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> - Données et intégration &copy; CEN Nouvelle-Aquitaine',
                        zIndex: 1,
                    });
                const m4 = L.tileLayer("https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
                    {
                        maxZoom: 100,
                        attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="http://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a> - Données et intégration &copy; CEN Nouvelle-Aquitaine)',
                        zIndex: 1,
                    });

                const scan25 = L.tileLayer.wms(
                    "https://opendata.cen-nouvelle-aquitaine.org/geoserver/acces_appli_web/ows?",
                    {
                        layers: 'SCAN25TOUR_PYR-JPEG_WLD_WM',
                        format: 'image/png',
                        transparent: true,
                        attribution: 'Fond cartographique &copy; IGN - Données et intégration &copy; CEN Nouvelle-Aquitaine',
                        zIndex: 1,
                        maxZoom: 100,
                    });

                L.tileLayer.wms('https://opendata.cen-nouvelle-aquitaine.org/geoserver/administratif/wms?', {
                    layers: 'foncier_agg',
                    format: 'image/png',
                    transparent: true,
                    zIndex: 1000,
                    tiled: true,
                    tilesOrigin: -74.047185 + "," + 40.679648

                }).addTo(this.map);

                // Add default basemap
                osm.addTo(this.map);

                // Add controls
                L.control.layers(
                    {
                        'OSM': osm,
                        "OSM FR": m3,
                        "OSM Topo": m4,
                        "Ortho IGN": m1,
                        "SCAN25 IGN": scan25,
                        "Infra-rouge IGN": m6,
                    },
                )
                    .addTo(this.map);
            },
            createMiniMap() {
                // Filter duplicate
                this.otherParcelsFilter = this.otherParcels.map(e => e['idpar'])
                    // store the indexes of the unique objects
                    .map((e, i, final) => final.indexOf(e) === i && i)
                    // eliminate the false indexes & return unique objects
                    .filter((e) => this.otherParcels[e]).map(e => this.otherParcels[e]);

                this.otherParcelsSum = this.otherParcelsFilter.reduce((acc, curr) => acc + curr.dcntpa, 0);

                // TODO draw stuff from parent data geojson

                this.MiniMapLayer = new L.FeatureGroup();
                this.MiniMapLayer.addTo(this.map);

                // Add geom on map and add plot to selectedPlots
                this.otherParcels.forEach((p) => {
                    // Loop each geom
                    let plot = L.geoJSON(JSON.parse(p.geompar), {
                        color: '#7957d5',
                        fillColor: '#7957d5',
                        weight: 1,
                        fillOpacity: 0.5,
                        myCustomId: p.idpar,
                    });
                    // this.contenanceToSurface(p.dcntpa);
                    // Popup on mouseover
                    plot.bindPopup(
                        `<b>ID parcelle : </b>${p.idpar}<br>` +
                        `<b>Surface m² : </b>${this.contenanceToSurface(p.dcntpa)}<br>` +
                        `<b>Type : </b>${p.cgrnumdtxt}<br>` +
                        `<b>Type bât. : </b>${p.tlocdomin}<br>` +
                        `<b>Nb. batiments : </b>${p.nbat}<br>` +
                        `<b>Compte de propriété communal : </b>${p.idprocpte}<br>`
                    );

                    plot.on('mouseover', (e) => {
                        // Highlight
                        e.target.setStyle({
                            weight: 3,
                            color: '#fe4a49',
                            dashArray: '',
                            fillOpacity: 0.7
                        });
                    });
                    plot.on('mouseout', (e) => {
                        e.target.setStyle({
                            "weight": 1,
                            "opacity": 1,
                            "fillOpacity": 0.6,
                            "color": '#995d81',
                        })
                    });
                    this.MiniMapLayer.addLayer(plot);

                    // Draw marker on centroid clicked parcel
                    delete L.Icon.Default.prototype._getIconUrl;
                    L.Icon.Default.mergeOptions({
                        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
                        iconUrl: require("leaflet/dist/images/marker-icon.png"),
                        shadowUrl: require("leaflet/dist/images/marker-shadow.png")
                    });
                    let marker = L.marker([this.xy[0], this.xy[1]]);
                    let popup = marker.bindPopup('Parcelle sélectionnée');
                    popup.openPopup();
                    this.MiniMapLayer.addLayer(marker);
                });

                this.map.fitBounds(this.MiniMapLayer.getBounds())
            },

        },
        data() {
            return {
                md: {},
                isActive: true,
                p: 0, // Loop for each owner
                MiniMapLayer: {},
                otherParcelsFilter: [],
                otherParcelsSum: 0,
            }
        },
    }
</script>

<style scoped>

    #minimap {
        height: 630px;
        z-index: 1;
    }

    .bat {
        background-color: #ffdd57;
    }

    .original {
        background-color: lightblue;
    }

    .fixedmap {
        position: fixed;
        width: 46%;
    }

</style>
