<template>
    <div id="app">
        <div v-bind:class="{ grayscale : isGrayscale }">
            <div id="mainmap">
                <b-button
                    icon-left="paint-roller"
                    :label="isGrayscale ? 'Couleur' : 'Niveau de gris'"
                    size="is-small"
                    type="is-info"
                    style="z-index: 1001; margin-left: 60px; margin-top: 10px"
                    @click="setGrayScale()">
                </b-button>

            </div>
        </div>
    </div>
</template>

<script>
    /*
     */
    import L from 'leaflet';
    import '../../node_modules/leaflet/dist/leaflet.css';
    import 'leaflet.vectorgrid';
    import 'leaflet-draw';
    import '../../node_modules/leaflet-draw/dist/leaflet.draw.css';
    import 'leaflet.pattern';
    import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';
    import '../../node_modules/leaflet-geosearch/dist/geosearch.css';
    import {bus} from '@/main';
    import store from './store';
    // import * as turf from '@turf/turf'

    export default {
        name: "MapFoncicen",
        mounted() {
            // Base map and background layers
            this.initMap();
            this.animationLayers();
            this.WMS();
        },
        created() {
            // Reload if popup pref is modified
            bus.$on('changePopup', (e) => {
                this.popup = e;
                if (this.map.getZoom() >= this.zoomParcelle) {
                    bus.$emit('updateMap', true);
                }
            });
            // Reload if theme color pref is modified
            bus.$on('changeThemeColor', () => {
                if (this.map.getZoom() >= this.zoomParcelle) {
                    bus.$emit('updateMap', true);
                }
            });
            bus.$on('remove', (e) => {
                this.plots.eachLayer((layer) => {
                    if (layer.options.myCustomId === e) {
                        this.map.removeLayer(layer)
                        for (var i = 0; i < this.selectedPlots.length; i++) {
                            if (this.selectedPlots[i].idparcelle === e) {
                                this.selectedPlots.splice(i, 1);
                            }
                        }
                        bus.$emit('selectedPlots', this.selectedPlots, 0);
                    }
                });
            });
            bus.$on('highlight', (e) => {
                this.plots.eachLayer((layer) => {
                    if (layer.options.myCustomId === e.layer) {
                        e.val ? layer.setStyle({
                            color: '#7957d5',
                            fillColor: e.action === 'remove' ? '#ff3860' : '#ffdd57',
                            weight: 1,
                            fillOpacity: 1,
                        }) : layer.setStyle({
                            color: '#7957d5',
                            fillColor: '#7957d5',
                            weight: 1,
                            fillOpacity: 0.5,

                        });
                    }
                });
            });
            bus.$on('newBounds', (e) => {
                // Remove old bounds
                this.focusgeom.clearLayers();
                // Add new bounds
                let bounds = L.geoJSON(JSON.parse(e), {
                    interactive: false,
                    color: '#f14668',
                    weight: 4,
                    fillOpacity: 0,
                    myCustomId: 'bounds',
                });
                // Add to map
                bounds.addTo(this.focusgeom);
                this.map.fitBounds(bounds.getBounds(), {
                    padding: [1, 1]
                });
            });
                bus.$on('newSpot', (x, y) => {
                    console.log(x, y)
                    // Remove old bounds
                    this.focusgeom.clearLayers();
                    // Add new bounds
                    let spot = L.circleMarker([y, x], {
                        radius: 6,
                        color: '#f14668',
                        weight: 4,
                        fillOpacity: 0,
                        myCustomId: 'bounds',
                    });
                    // Add to map
                    spot.addTo(this.focusgeom);
                    this.map.setView([y, x], 15);
                });
            // TODO here
                bus.$on('triggerModeAddPlots', (e) => {
                    if (e) {
                        document.getElementById("mainmap").style.cursor = "crosshair";
                        this.map.on('click', (m) => {
                            this.$axios.get(`findParcelle?lat=${m.latlng.lat}&lon=${m.latlng.lng}&all=false`)
                                .then(function (response) {
                                    if (response.data.parcelle.length) {
                                        // Avoid duplicates // Remove plot if click on selected plot
                                        for (let i = 0; i < this.selectedPlots.length; i++) {
                                            if (this.selectedPlots[i].idparcelle === response.data.parcelle[0].idparcelle) {
                                                // Remove plot from list
                                                if (this.selectedPlots[i].idparcelle === response.data.parcelle[0].idparcelle) {
                                                    this.selectedPlots.splice(i, 1);
                                                }
                                                // Remove layer
                                                this.plots.eachLayer((layer) => {
                                                    if (layer.options.myCustomId === response.data.parcelle[0].idparcelle) {
                                                        this.map.removeLayer(layer)
                                                    }
                                                })
                                                bus.$emit('selectedPlots', this.selectedPlots, 0);
                                                this.ToastRemove();
                                                return;
                                            }
                                        }
                                        // Add geom on map and add plot to selectedPlots
                                        var plot = L.geoJSON(JSON.parse(response.data.parcelle[0].st_asgeojson), {
                                            color: '#7957d5',
                                            fillColor: '#7957d5',
                                            weight: 1,
                                            fillOpacity: 0.5,
                                            myCustomId: response.data.parcelle[0].idparcelle,
                                        });
                                        this.plots.addLayer(plot)
                                        plot.addTo(this.map)
                                        this.selectedPlots.push(response.data.parcelle[0])
                                        this.ToastAdd();
                                        bus.$emit('selectedPlots', this.selectedPlots, response.data.contact[0].idcontact);
                                    }
                                }.bind(this))
                                .catch(error => console.log(error));
                        });
                    } else if (!e) {
                        // Remove crosshair cursor
                        document.getElementById("mainmap").style.cursor = "grab";
                        // Deactivate on map click
                        this.map.off('click');
                        // Reset array of selected plots
                        this.selectedPlots = [];
                        bus.$emit('selectedPlots', this.selectedPlots, 0);
                        // Remove all layers
                        console.log("map", this.map)
                        console.log("plots", this.plots)
                        this.plots.eachLayer((layer) => {
                            this.map.removeLayer(layer);
                        }); // Remove plots layers
                    }
                });

            bus.$on('getOwner', (e) => {
                if (e) {
                    // Remove info popup to avoid conflict
                    bus.$emit('changePopup', false);

                    document.getElementById("mainmap").style.cursor = "crosshair";
                    this.map.on('click', (m) => {
                        this.$axios.get(`findParcelle?lat=${m.latlng.lat}&lon=${m.latlng.lng}&all=true`)
                            .then(function (response) {
                                this.$axios.get(`getOwnerParcel?id=${response.data.parcelle[0].idparcelle}`)
                                    .then(function (res) {
                                        bus.$emit('retrieveOwner', res, [m.latlng.lat, m.latlng.lng]);
                                    }.bind(this))
                                    .catch(error => console.log(error));
                            }.bind(this))
                            .catch(error => console.log(error));
                    });
                } else if (!e) {
                    // Rollback popup state
                    this.popup = store.getters.popup;
                    bus.$emit('changePopup', store.getters.popup);

                    document.getElementById("mainmap").style.cursor = "grab";
                    this.map.off('click');      // Deactivate on map click
                }
            });

            bus.$on('addGeoserverlayer', (e) => {
                let l = this.geoserverLayers[e.name];
                if ( l !== undefined && this.geoserverLayerGroup.hasLayer(l)) {
                    this.geoserverLayerGroup.removeLayer(l);
                } else {
                    // Add the layer to the Geoserver group
                    const wms = L.tileLayer.wms(`https://opendata.cen-nouvelle-aquitaine.org/${e.repo}/wms?`, {
                        layers: e.name,
                        format: 'image/png',
                        transparent: true,
                        zIndex: 100,
                        minZoom: 8,
                        tiled: true,
                        opacity : e.sliderValue / 10
                    });
                    this.geoserverLayerGroup.addLayer(wms);
                    this.geoserverLayers[e.name] = wms;
                }
            });
            bus.$on('opacityGeoserverlayer', (e) => {
                let l = this.geoserverLayers[e.name];
                if ( l !== undefined && this.geoserverLayerGroup.hasLayer(l)) {
                    l.setOpacity(e.sliderValue / 10);
                } else {
                    console.log(`Layer ${e.name} not loaded`)
                }
            });
            bus.$on('updateMap', (e) => {
                if (e) {
                    let currentBounds = this.map.getBounds();
                    let minX = currentBounds._southWest.lng;
                    let minY = currentBounds._southWest.lat;
                    let maxX = currentBounds._northEast.lng;
                    let maxY = currentBounds._northEast.lat;
                    this.storedParcelles(minX, minY, maxX, maxY);
                }
            });
            // Highlight selected plot
            bus.$on('fillPlot', (e, fill) => {
                if (fill) {
                    // Store highlighted plots
                    this.multi_select_highlight.push(e);
                    this.stored.eachLayer((layer) => {
                        if (layer.id === e) {
                            layer.setStyle({
                                fillOpacity: 1,
                            });
                        }
                    });
                } else {
                    // Remove from list
                    this.multi_select_highlight = this.multi_select_highlight.filter(item => item !== e)
                    this.stored.eachLayer((layer) => {
                        if (layer.id === e) {
                            layer.setStyle({
                                fillOpacity: 0.6,
                            });
                        }
                    });
                }
            });
        },
        beforeDestroy() {
            bus.$off('changePopup');
            bus.$off('changeThemeColor');
            bus.$off('remove');
            bus.$off('highlight');
            bus.$off('newBounds');
            bus.$off('newSpot');
            bus.$off('triggerModeAddPlots');
            bus.$off('getOwner');
            bus.$off('addGeoserverlayer');
            bus.$off('opacityGeoserverlayer');
            bus.$off('updateMap');
            bus.$off('fillPlot');
        },
        methods: {
            setGrayScale() {
                this.isGrayscale = !this.isGrayscale
            },
            // TODO virer cette function qui sert à rien ici ?
            /*
            getPrefs() {
                if (!store.getters.color) {
                    this.$axios.get('prefs')
                        .then(function (response) {
                            let layers = JSON.parse(response.data[0].layers);
                            let color = response.data[0].themecolor;
                            let popup = response.data[0].popup;
                            store.commit('SET_COLOR', color);
                            store.commit('SET_LAYERS', layers);
                            store.commit('SET_POPUP', popup);
                            // Set generic WMS layers
                            layers.forEach(l => {
                                bus.$emit('addGeoserverlayer', l);
                                bus.$emit('opacityGeoserverlayer', l);
                            });
                            this.popup = popup;
                            bus.$emit('changeThemeColor', color);
                        }.bind(this))
                        .catch(error => console.log(error));
                } else {
                    let color = store.getters.color;
                    let layers = store.getters.geoserverLayers;
                    let popup = store.getters.popup;
                    layers.forEach(l => {
                        bus.$emit('addGeoserverlayer', l);
                        bus.$emit('opacityGeoserverlayer', l);
                    });
                    this.popup = popup;
                    bus.$emit('changeThemeColor', color);
                }
            },

             */

            initMap() {
                // Map declaration
                this.map = L.map('mainmap').setView([45.05, 0.6167], 7);
                // Add a layers group to manage WMS layer from the geoserver
                this.geoserverLayerGroup.addTo(this.map)
                // Base layers
                let osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 100,
                    attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    zIndex: 1,
                });
                // IGN layers
                const m1 = L.tileLayer(
                    "https://data.geopf.fr/wmts?SERVICE=WMTS&VERSION=1.0.0" +
                    "&REQUEST=GetTile" +
                    "&STYLE=normal" +
                    "&TILEMATRIXSET=PM" +
                    "&FORMAT=image/jpeg"+
                    "&LAYER=ORTHOIMAGERY.ORTHOPHOTOS"+
                    "&TILEMATRIX={z}" +
                    "&TILEROW={y}" +
                    "&TILECOL={x}",
                    {
                        attribution: 'Fond cartographique &copy; IGN - Données et intégration &copy; CEN Nouvelle-Aquitaine',
                        zIndex: 1,
                        maxZoom: 100,
                    });
                const m6 = L.tileLayer(
                    "https://data.geopf.fr/wmts?SERVICE=WMTS&VERSION=1.0.0" +
                    "&REQUEST=GetTile" +
                    "&STYLE=normal" +
                    "&TILEMATRIXSET=PM" +
                    "&FORMAT=image/jpeg"+
                    "&LAYER=ORTHOIMAGERY.ORTHOPHOTOS.IRC"+
                    "&TILEMATRIX={z}" +
                    "&TILEROW={y}" +
                    "&TILECOL={x}",
                    {
                        attribution: 'Fond cartographique &copy; IGN - Données et intégration &copy; CEN Nouvelle-Aquitaine',
                        zIndex: 1,
                        maxZoom: 100,
                    });

                const m3 = L.tileLayer("https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
                    {
                        maxZoom: 100,
                        attribution: '&copy; Openstreetmap France | &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> - Données et intégration &copy; CEN Nouvelle-Aquitaine',
                        zIndex: 1,
                    });
                const m4 = L.tileLayer("https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
                    {
                        maxZoom: 100,
                        attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="http://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a> - Données et intégration &copy; CEN Nouvelle-Aquitaine)',
                        zIndex: 1,
                    });

                const scan25 = L.tileLayer.wms(
                    "https://opendata.cen-nouvelle-aquitaine.org/geoserver/acces_appli_web/ows?",
                    {
                        layers: 'SCAN25TOUR_PYR-JPEG_WLD_WM',
                        format: 'image/png',
                        transparent: true,
                        attribution: 'Fond cartographique &copy; IGN - Données et intégration &copy; CEN Nouvelle-Aquitaine',
                        zIndex: 1,
                        maxZoom: 100,
                    });

                // GeoSearch address
                const provider = new OpenStreetMapProvider();
                const searchControl = new GeoSearchControl({
                    provider: provider,
                });
                this.map.addControl(searchControl);
                // Initialise the FeatureGroup to store editable layers
                let editableLayers = new L.FeatureGroup();
                this.map.addLayer(editableLayers);
                let drawPluginOptions = {
                    position: 'topright',
                    draw: {
                        polygon: {
                            allowIntersection: false, // Restricts shapes to simple polygons
                            drawError: {
                                color: '#e1e100', // Color the shape will turn when intersects
                                message: '<strong>Ce n\'est pas possible de faire ça!</strong>' // Message that will show when intersect
                            },
                            shapeOptions: {
                                color: '#97009c'
                            }
                        },
                        // disable toolbar item by setting it to false
                        polyline: false,
                        circle: false, // Turns off this drawing tool
                        circlemarker: false, // Turns off this drawing tool
                        rectangle: false,
                        marker: false,
                    },
                    edit: {
                        featureGroup: editableLayers, //REQUIRED!!
                        remove: true
                    }
                };

                // Layer control on map
                this.stored = L.layerGroup();
                // Missing plot to add to sites
                this.missingPlots = L.layerGroup();

                // Add default basemap
                osm.addTo(this.map);

                // Add controls
                L.control.layers(
                    {
                        'OSM': osm,
                        "OSM FR": m3,
                        "OSM Topo": m4,
                        "Ortho IGN": m1,
                        "SCAN25 IGN": scan25,
                        "Infra-rouge IGN": m6,
                    },
                    {
                        'Saisie': this.stored
                    }
                )
                    .addTo(this.map);


                // TODO draw stuff
                let drawControl = new L.Control.Draw(drawPluginOptions);
                this.map.addControl(drawControl);

                this.map.on('draw:created', function (e) {
                    let layer = e.layer;
                    editableLayers.addLayer(layer);
                });
            },

            animationLayers() {
                // Load stored parcelles or aires on map move
                this.map.on('moveend', () => {

                    // If zoom if more than 14, load parcelles
                    if (this.map.getZoom() >= this.zoomParcelle) {

                        this.storedAiresBounds = {};


                        // get current bounds
                        let currentBounds = this.map.getBounds();
                        let minX = currentBounds._southWest.lng;
                        let minY = currentBounds._southWest.lat;
                        let maxX = currentBounds._northEast.lng;
                        let maxY = currentBounds._northEast.lat;

                        if (this.storedParcellesBounds.maxX === undefined) {
                            // Save bounds of loaded parcelles
                            this.storedParcellesBounds.minX = minX;
                            this.storedParcellesBounds.maxX = maxX;
                            this.storedParcellesBounds.minY = minY;
                            this.storedParcellesBounds.maxY = maxY;

                            this.storedParcelles(
                                minX - this.offsetStoredBounds,
                                minY - this.offsetStoredBounds,
                                maxX + this.offsetStoredBounds,
                                maxY + this.offsetStoredBounds
                            );

                        } else if (
                            maxX > (this.storedParcellesBounds.maxX + this.offsetStoredBounds) ||
                            maxY > (this.storedParcellesBounds.maxY + this.offsetStoredBounds) ||
                            minX < (this.storedParcellesBounds.minX - this.offsetStoredBounds) ||
                            minY < (this.storedParcellesBounds.minY - this.offsetStoredBounds)
                        ) {
                            this.storedParcellesBounds = {
                                minX: minX,
                                minY: minY,
                                maxX: maxX,
                                maxY: maxY,
                            };
                            this.storedParcelles(
                                minX - this.offsetStoredBounds,
                                minY - this.offsetStoredBounds,
                                maxX + this.offsetStoredBounds,
                                maxY + this.offsetStoredBounds
                            );
                        }


                    } else if (this.map.getZoom() < this.zoomParcelle && this.map.getZoom() > this.zoomAire) { // If 10 < zoom < 14, load aires foncières

                        this.storedParcellesBounds = {};


                        // get current bounds
                        let currentBounds = this.map.getBounds();
                        let minX = currentBounds._southWest.lng;
                        let minY = currentBounds._southWest.lat;
                        let maxX = currentBounds._northEast.lng;
                        let maxY = currentBounds._northEast.lat;

                        // Check if old bounds existing
                        if (!this.storedAiresBounds.maxX) {
                            // Save bounds of loaded aires
                            this.storedAiresBounds = {
                                minX: minX,
                                minY: minY,
                                maxX: maxX,
                                maxY: maxY,
                            };
                            this.storedAires(
                                minX - this.offsetStoredBounds,
                                minY - this.offsetStoredBounds,
                                maxX + this.offsetStoredBounds,
                                maxY + this.offsetStoredBounds
                            );

                        } else if (maxX > (this.storedAiresBounds.maxX + this.offsetStoredBounds) ||
                            maxY > (this.storedAiresBounds.maxY + this.offsetStoredBounds) ||
                            minX < (this.storedAiresBounds.minX - this.offsetStoredBounds) ||
                            minY < (this.storedAiresBounds.minY - this.offsetStoredBounds)) {
                            this.storedAiresBounds = {
                                minX: minX,
                                minY: minY,
                                maxX: maxX,
                                maxY: maxY,
                            };

                            this.storedAires(
                                minX - this.offsetStoredBounds,
                                minY - this.offsetStoredBounds,
                                maxX + this.offsetStoredBounds,
                                maxY + this.offsetStoredBounds
                            );
                        }

                    } else {
                        this.stored.clearLayers();
                        this.storedAiresBounds = {};
                        this.storedParcellesBounds = {};
                    }
                });

                // Reset to store selected plots
                this.selectedPlots = [];
                this.plots = L.layerGroup();
                // Prepare to receive stored plots
                // this.stored = L.layerGroup();
                this.map.addLayer(this.stored);
                // Prepare to receive focus
                this.focusgeom = L.layerGroup();
                this.map.addLayer(this.focusgeom);
            },

            WMS() {
                L.tileLayer.wms('https://opendata.cen-nouvelle-aquitaine.org/geoserver/administratif/wms?', {
                    layers: 'foncier_agg',
                    format: 'image/png',
                    transparent: true,
                    zIndex: 1000,
                    tiled: true,
                    tilesOrigin: -74.047185 + "," + 40.679648,
                    maxZoom: 100

                }).addTo(this.map);
            },

            storedAires(minX, minY, maxX, maxY) {
                this.$axios.get('storedAires?minX=' + minX + '&minY=' + minY + '&maxX=' + maxX + '&maxY=' + maxY)
                    .then(function (response) {
                        if (response.data.aires.length) {
                            this.stored.clearLayers();
                            // Draw each plot
                            response.data.aires.forEach((element) => {
                                var aire = new L.geoJSON(JSON.parse(element.geom), {
                                    style: {
                                        "weight": 1,
                                        "opacity": 1,
                                        "fillOpacity": 0.6,
                                        "color": '#995d81',
                                    }
                                });


                                // Popup on mouseover
                                aire.bindPopup(
                                    '<b>ID aire foncière: </b>' +
                                    element.idaire +
                                    '<br><b>Contenance aire foncière: </b>' +
                                    this.contenanceToSurface(element.contenance) +
                                    '<br><b>Nombre de parcelles : </b>' +
                                    element.nb_parcelle +
                                    '<br><b>Aire foncière: </b>' +
                                    element.libaire);

                                aire.on('mouseover', (e) => {
                                    let popup = e.target.getPopup();
                                    popup.setLatLng(e.latlng).openOn(this.map);
                                    // Highlight aire foncière
                                    e.target.setStyle({
                                        weight: 3,
                                        color: '#fe4a49',
                                        dashArray: '',
                                        fillOpacity: 0.7
                                    });
                                });
                                aire.on('mouseout', (e) => {
                                    e.target.closePopup();
                                    e.target.setStyle({
                                        "weight": 1,
                                        "opacity": 1,
                                        "fillOpacity": 0.6,
                                        "color": '#995d81',
                                    })
                                });
                                aire.on('mousemove', (e) => {
                                    e.target.closePopup();
                                    let popup = e.target.getPopup();
                                    popup.setLatLng(e.latlng).openOn(this.map);
                                });

                                this.stored.addLayer(aire);
                            });

                            this.currentStoredLayer = 'aires';

                        } else { // No stored plots in the database
                            this.stored.clearLayers();
                        }
                    }.bind(this))
                    .catch(error => console.log(error));
            },

            storedParcelles(minX, minY, maxX, maxY) {
                this.$axios.get('storedParcelle?minX=' + minX + '&minY=' + minY + '&maxX=' + maxX + '&maxY=' + maxY)
                    .then(function (response) {
                        if (response.data.parcelles.length) {
                            this.stored.clearLayers();
                            // Draw each plot
                            response.data.parcelles.forEach((element) => {
                                // Select theme color
                                let color;
                                switch (store.getters.color) {
                                    case 'categorie':
                                        color = element.color;
                                        break;
                                    case 'interet':
                                        color = element.colorinteret;
                                        break;
                                }
                                var geom = new L.geoJSON(JSON.parse(element.geom), {
                                    style: {
                                        weight: 2,
                                        opacity: 1,
                                        color: 'black',
                                        fillOpacity: this.multi_select_highlight.includes(element.idparcelle) ? 1 : 0.6,
                                        fillColor: color, // Get color from categorie table
                                    }
                                });
                                // Set idparcelle as layer id to retreive layer
                                geom.id = element.idparcelle;
                                // Popup on mouseover
                                if (this.popup) {
                                    geom.bindPopup(
                                        '<b>Commune: </b>' +
                                        element.nom +
                                        '<br><br><b>ID parcelle: </b>' +
                                        element.idparcelle +
                                        '<br><b>Section: </b>' +
                                        element.section +
                                        '<br><b>Numéro: </b>' +
                                        element.numero +
                                        '<br><b>Contenance: </b>' +
                                        this.contenanceToSurface(element.contenance) +
                                        '<br><br><b>Aire foncière: </b>' +
                                        element.libaire +
                                        '<br><b>Intérêt: </b>' +
                                        element.libinteret +
                                        '<br><br>&#x1F4C5; <b>' +
                                        element.dateevenement +
                                        '<br>&#x1F9D1; <b>' +
                                        element.firstname + ' ' + element.lastname +
                                        '<br>&#x1F4CD; ' +
                                        element.libevenement +
                                        '</p></b>');

                                    geom.on('mouseover', (e) => {
                                        let popup = e.target.getPopup();
                                        popup.setLatLng(e.latlng).openOn(this.map);
                                    });
                                    geom.on('mouseout', (e) => {
                                        e.target.closePopup();
                                    });
                                    geom.on('mousemove', (e) => {
                                        e.target.closePopup();
                                        let popup = e.target.getPopup();
                                        popup.setLatLng(e.latlng).openOn(this.map);
                                    });
                                }

                                // Edit on click if toggle mod
                                geom.on('click', () => {
                                    bus.$emit('selectedPlotToMod', element);
                                    bus.$emit('selectedPlotToModEvent', element);
                                    // bus.$emit('newBounds', element.geom);
                                });
                                this.stored.addLayer(geom);
                            });

                            response.data.aires.forEach((element) => {
                                var aire = new L.geoJSON(JSON.parse(element.geom), {
                                    interactive: false,
                                    style: {
                                        weight: 3,
                                        opacity: 1,
                                        fillOpacity: 0,
                                        dashArray: '4',
                                        color: '#028090', // Get color from categorie table
                                    }
                                });

                                this.stored.addLayer(aire);
                            });

                        } else { // No stored plots in the database
                            this.stored.clearLayers();
                        }
                    }.bind(this))
                    .catch(error => console.log(error));
            },
            ToastRemove() {
                this.$buefy.toast.open({
                    duration: 300,
                    message: `Sélection supprimée`,
                    position: 'is-top',
                    type: 'is-danger'
                })
            },

            ToastAdd() {
                this.$buefy.toast.open({
                    duration: 300,
                    message: `Sélection ajoutée`,
                    position: 'is-top',
                    type: 'is-success'
                })
            },

        },
        data() { /* Data properties will go here */
            return {
                // Geoserver variables
                geoserverLayerGroup: L.layerGroup(),
                geoserverLayers: {},
                // Grayscale button
                isGrayscale: false,
                // Other
                popup: false,
                storedAiresBounds: {},
                storedParcellesBounds: {},
                offsetStoredBounds: 0.05,
                currentStoredLayer: 'aires',
                multi_select_highlight: [],
                // Zoom min for parcelle
                zoomParcelle: 16,
                // Zoom max for aires
                zoomAire: 12,
            }
        }
    }
</script>

<style scoped>

    #mainmap {
        width: 100%;
        height: calc(100vh - 80px);
        z-index: 1;
    }




</style>

<style>

/* Trick to switch in B&W */
div.grayscale .leaflet-tile {
    filter: grayscale(100%);
}
</style>

