import Vue from 'vue'
import Vuex from 'vuex';
import App from './App.vue'
import router from './router'

// Axios global config
import  axios  from  'axios'
const axiosConfig = {
    baseURL: process.env.VUE_APP_AXIOSBASEURL,  // Set URL for API
    timeout: process.env.VUE_APP_AXIOSTIMEOUT,  // Set timeout
};
// Setting up Axios on Vue Instance, for use via this.$axios
Vue.prototype.$axios = axios.create(axiosConfig);

// CSS framework
import Buefy from 'buefy'
// Comment precompiled css for custom
import 'buefy/dist/buefy.css'

Vue.use(Buefy, {
    defaultIconPack: 'fas'
});

// Excel export
import VueExcelXlsx from "vue-excel-xlsx"; // https://github.com/t-chatoyan/vue-excel-xlsx
Vue.use(VueExcelXlsx);

Vue.config.productionTip = true

// Event Bus to pass data between components, see : https://blog.logrocket.com/using-event-bus-in-vue-js-to-pass-data-between-components/
export const bus = new Vue();

// VUEX
Vue.use(Vuex);

// Global functions
Vue.mixin({
    methods: {
        contenanceToSurface: function (areaInSquareMeters) {
            // Conversion factors
            const sqmToHectares = 0.0001;
            const sqmToAres = 0.01;
            const sqmToCentiares = 1;

            // Convert square meters to different units
            const hectares = Math.floor(areaInSquareMeters * sqmToHectares);
            const ares = Math.floor((areaInSquareMeters - hectares / sqmToHectares) * sqmToAres);
            const centiares = Math.round((areaInSquareMeters - hectares / sqmToHectares - ares / sqmToAres) * sqmToCentiares);

            // Format the values with leading zeros if needed
            const hectaresStr = hectares.toString().padStart(2, '0');
            const aresStr = ares.toString().padStart(2, '0');
            const centiaresStr = centiares.toString().padStart(2, '0');

            // Construct the final string
            const result = `${hectaresStr} ha ${aresStr} a ${centiaresStr} ca`;

            return result;
        },
        contenanceToSurfaceBK: function (contenance) { // All credit to https://github.com/etalab/cadastre.data.gouv.fr
            let unit = ''
            let surface = 0
            const contenanceLength = (contenance.toString().length)

            if (isNaN(contenance) || contenance < 0) {
                unit = ''
                surface = ''
            } else if (contenanceLength < 3) {
                unit = ' m²'
                surface = contenance
            } else if (contenanceLength >= 3 && contenanceLength < 5) {
                unit = ' a'
                surface = (contenance / 100).toFixed(2)
                surface = surface.replace('.', ',')
            } else {
                unit = ' ha'
                surface = (contenance / 10000).toFixed(2)
                surface = surface.replace('.', ',')
            }
            return `${surface}${unit}`
        },
    },
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
