import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeFoncicen from '../views/Home.vue'
import AnimationFoncicen from '../views/Animation.vue'
import AboutFoncicen from "../views/AboutFoncicen.vue";
import SitesGeres from "../views/SitesGeres.vue";

Vue.use(VueRouter)

/*
const ifAuthenticated = (to, from, next) => {
    if (localStorage.getItem('toktok')) {
        next()
        return
    }
    next('/')
}
*/

const routes = [
    {
        path: '/',
        name: 'HomeFoncicen',
        component: HomeFoncicen
    },
    {
        path: '/ajouter',
        name: 'ajouter',
        // beforeEnter: ifAuthenticated, // Protect if toktok is not set
        component: AboutFoncicen
    },
    {
        path: '/sites',
        name: 'sites',
        component: SitesGeres
    },
    {
        path: '/animation',
        name: 'animation',
        // beforeEnter: ifAuthenticated, // Protect if toktok is not set
        component: AnimationFoncicen
    },
    {
        path: '/apropos',
        name: 'apropos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/APropos')
    },
    {
        path: '/aide',
        name: 'aide',
        // beforeEnter: ifAuthenticated, // Protect if toktok is not set
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Help')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        // beforeEnter: ifAuthenticated, // Protect if toktok is not set
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard')
    },
    {
        path: '/logigrammes',
        name: 'logigrammes',
        // beforeEnter: ifAuthenticated, // Protect if toktok is not set
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Logigrammes')
    },
]

const router = new VueRouter({
  routes
})

export default router
