<template>
    <div id="tabs">


        <div class="columns">
            <div class="column">
                <b-switch v-model="addParcelleSwitch"
                          :disabled="modParcelleSwitch || ownerParcelleSwitch"
                          type="is-success"
                          @change.native="modeAddPlots(addParcelleSwitch)">
                    Ajouter
                </b-switch>

                <b-switch v-model="modParcelleSwitch"
                          :disabled="addParcelleSwitch || ownerParcelleSwitch"
                          type="is-warning"
                          @change.native="modeEditPlots(modParcelleSwitch)">
                        Modifier
                </b-switch>

                <b-switch v-model="ownerParcelleSwitch"
                          :disabled="modParcelleSwitch || addParcelleSwitch"
                          type="is-info"
                          @change.native="ownerPlots(ownerParcelleSwitch)">
                    Propriétaire
                </b-switch>

            </div>
            <div class="column">
                <b-button icon-left="calendar-plus"
                          type="is-primary"
                          v-if="selectedPlots.length > 0 && addParcelleSwitch && this.activeTab <= 2 "
                          @click="savecard">
                    Valider le choix des parcelles
                </b-button>
            </div>
        </div>

        <div class="field"
             v-if="selectedPlots.length">
            <b-field grouped group-multiline>
                <div class="control">
                    <b-taglist attached>
                        <b-tag type="is-dark" size="is-medium">Nombre total</b-tag>
                        <b-tag type="is-info" size="is-medium">{{selectedPlots.length}}</b-tag>
                    </b-taglist>
                </div>

                <div class="control">
                    <b-taglist attached>
                        <b-tag type="is-dark" size="is-medium">Surface totale</b-tag>
                        <b-tag type="is-info" size="is-medium">{{contenanceToSurface(sumContenance())}}</b-tag>
                    </b-taglist>
                </div>
            </b-field>
        </div>

        <b-tabs type="is-boxed" v-model="activeTab">

            <!--
            ██████╗ ██╗      ██████╗ ████████╗███████╗
            ██╔══██╗██║     ██╔═══██╗╚══██╔══╝██╔════╝
            ██████╔╝██║     ██║   ██║   ██║   ███████╗
            ██╔═══╝ ██║     ██║   ██║   ██║   ╚════██║
            ██║     ███████╗╚██████╔╝   ██║   ███████║
            ╚═╝     ╚══════╝ ╚═════╝    ╚═╝   ╚══════╝
            -->

            <b-tab-item label="Détails" icon="edit">

                <div v-if="!addParcelleSwitch && !modParcelleSwitch">
                    <div class="card">
                        <div class="card-content">
                            <p>
                                <b-icon icon="life-ring"></b-icon>
                                Cliquez sur <b>Ajouter</b> pour ajouter une ou plusieurs parcelle(s) non-suivie(s).
                                L'ajout d'un premier évènement est obligatoire.
                            </p>
                            <p>
                                <b-icon icon="life-ring"></b-icon>
                                Cliquez sur <b>Modifier</b> pour modifier les informations d'une parcelle (habitat et espèces cibles),
                                affection à une aire foncière...
                            </p>
                        </div>
                    </div>

                </div>

                <div class="field">

                    <b-field label="Ajouter à une aire foncière existante"
                             v-if="!addNewAire & selectedPlots.length > 0">
                        <b-autocomplete
                                v-model="reduceAires"
                                placeholder="Rechercher une aire existante"
                                keep-first
                                open-on-focus
                                :data="getFilteredAires"
                                field="libaire"
                                @select="option => selectedAire = option">
                        </b-autocomplete>
                    </b-field>

                    <b-field    label="Créer une nouvelle aire foncière"
                                v-if="addNewAire & selectedPlots.length > 0">
                        <b-input v-model="manualAire"></b-input>
                    </b-field>

                    <div class="buttons">
                        <b-button v-if="this.selectedAire.idaire"
                                  type="is-warning"
                                  icon-left="times-circle"
                                  @click="resetAire()">
                            Enlever de l'aire <b>{{ selectedAire.libaire }}</b>
                        </b-button>

                        <b-switch v-model="addNewAire"
                                  v-if="this.selectedPlots.length"
                                  type="is-info"
                                  @input="resetAire()">
                            Créer une nouvelle aire foncière
                        </b-switch>
                    </div>

                    <hr/>

                    <b-field
                            label="Intérêt des parcelles sélectionnées"
                            v-if="this.selectedPlots.length">
                        <b-select placeholder="Choix" v-model="postEvent.interet">
                            <option
                                    v-for="option in dictionary.interets"
                                    :value="option.idinteret"
                                    :key="option.idinteret"
                                    >
                                {{ option.libinteret }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field label="Sélection du dossier (NeoTerra, autre...)"
                             v-if="this.selectedPlots.length">
                        <b-select placeholder="Sélectionner un dossier"
                                  v-model="postEvent.dossier">
                            <option
                                    v-for="dossier in dossiers"
                                    :value="dossier.iddossier"
                                    :key="dossier.libdossier">
                                {{ dossier.libdossier }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field
                            label="Habitat cible des parcelles sélectionnées"
                            v-if="this.selectedPlots.length">
                        <b-select placeholder="Choix" v-model="postEvent.habitat">
                            <option
                                    v-for="option in dictionary.habitats"
                                    :value="option.idhabitat"
                                    :key="option.idhabitat">
                                {{ option.libhabitat }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field
                            label="Espèce cible des parcelles sélectionnées"
                            v-if="this.selectedPlots.length">
                        <b-taginput
                                v-model="postTags"
                                :data="filteredTags"
                                :before-adding="beforeAdding"
                                autocomplete
                                open-on-focus
                                field="nomvern"
                                icon="tags"
                                placeholder="Ajouter une espèce"
                                @typing="getFilteredTags">
                            <template slot-scope="props">
                                <em>{{props.option.nomlatin}}</em> - {{props.option.nomvern}}
                            </template>
                            <template slot="empty">
                                Aucune correspondance
                            </template>
                        </b-taginput>
                    </b-field>

                    <b-field label="Usage de la parcelle"
                             v-if="this.selectedPlots.length">
                        <b-select placeholder="Sélectionner un usage"
                                  v-model="postEvent.usage">
                            <option
                                    v-for="usage in usages"
                                    :value="usage.idusage"
                                    :key="usage.idusage">
                                {{ usage.libusage }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field label="Argumentaire à destination de la SAFER"
                             v-if="this.selectedPlots.length">
                        <b-input maxlength="500"
                                 type="textarea"
                                 v-model="postEvent.argumentaire">
                        </b-input>
                    </b-field>


                </div>
                <b-button icon-left="save"
                          type="is-success"
                          v-if="selectedPlots.length & modParcelleSwitch"
                          @click="modSaveParcelle">
                    Enregistrer
                </b-button>
                <b-button icon-left="trash"
                          type="is-danger"
                          v-if="selectedPlots.length & modParcelleSwitch"
                          @click="confirmDelete">
                    Supprimer la parcelle
                </b-button>
            </b-tab-item>

            <!--
            ██████╗ ██╗      ██████╗ ████████╗███████╗
            ██╔══██╗██║     ██╔═══██╗╚══██╔══╝██╔════╝
            ██████╔╝██║     ██║   ██║   ██║   ███████╗
            ██╔═══╝ ██║     ██║   ██║   ██║   ╚════██║
            ██║     ███████╗╚██████╔╝   ██║   ███████║
            ╚═╝     ╚══════╝ ╚═════╝    ╚═╝   ╚══════╝
            -->

            <b-tab-item label="Parcelles"
                        icon="draw-polygon"
                        :visible="selectedPlots.length > 0">
                <section>
                    <b-collapse
                            class="card"
                            v-for="(plot, index) of selectedPlots"
                            :key="index"
                            :open="isOpen === index"
                            @open="isOpen = index">
                        <div
                                slot="trigger"
                                slot-scope="props"
                                class="card-header"
                                role="button"
                                @mouseover="mouseoverHighlight(true, plot.idparcelle, 'focus')"
                                @mouseleave="mouseoverHighlight(false, plot.idparcelle, 'focus')">
                            <p class="card-header-title">
                                {{ plot.idparcelle }}
                            </p>
                            <a class="card-header-icon">
                                <b-icon
                                        :icon="props.open ? 'sort-up' : 'sort-down'">
                                </b-icon>
                            </a>
                        </div>
                        <div class="card-content"
                             @mouseover="mouseoverHighlight(true, plot.idparcelle, 'focus')"
                             @mouseleave="mouseoverHighlight(false, plot.idparcelle, 'focus')">
                            <div class="content">
                                <b>Commune :</b> {{ plot.commune }} <br />
                                <b>Prefixe :</b> {{ plot.prefixe }} <br />
                                <b>Section :</b> {{ plot.section }} <br />
                                <b>Numéro :</b> {{ plot.numero }} <br />
                                <b>Contenance :</b> {{ contenanceToSurface(plot.contenance) }}<br />
                                <b>Créée :</b> {{ plot.created }} <br />
                                <b>Mise à jour :</b> {{ plot.updated }} <br />
                                <b>Millesime :</b> {{ plot.millesime }} <br />
                            </div>
                        </div>

                        <footer class="card-footer">
                            <a class="card-footer-item button is-danger"
                               v-if="!modParcelleSwitch"
                               v-on:click="remove(plot.idparcelle)"
                               v-on:mouseover="mouseoverHighlight(true, plot.idparcelle, 'remove')"
                               v-on:mouseleave="mouseoverHighlight(false, plot.idparcelle, 'remove')">
                                Enlever</a>
                        </footer>
                    </b-collapse>
                </section>
            </b-tab-item>

            <b-tab-item label="Contacts"
                        icon="draw-polygon"
                        :visible="selectedPlots.length > 0">

                <p>
                    Sélectionner les personnes référentes de ou des parcelles sélectionnées.
                </p>
                <p>
                    Les référents sont les personnes qui supervise la suivi du dossier.
                </p>

                <hr>

                <b-field label="Salarié CEN référent"
                         v-if="this.selectedPlots.length">
                    <b-select placeholder="Sélectionner un salarié"
                                v-model="postEvent.refCEN">
                        <option
                                v-for="option in refCEN"
                                :value="option.idcontact"
                                :key="option.idcontact">
                            {{ option.nom }}
                        </option>
                    </b-select>
                </b-field>

                <b-field label="Technicien SAFER référent"
                         v-if="this.selectedPlots.length">
                    <b-select placeholder="Sélectionner un technicien"
                              v-model="postEvent.refSAFER"
                              >
                        <option
                                v-for="option in refSAFER"
                                :value="option.idcontact"
                                :key="option.idcontact">
                            {{ option.nom }} ({{ option.cp}})
                        </option>
                    </b-select>
                </b-field>
            </b-tab-item>


            <!--
            ███████╗██╗   ██╗███████╗███╗   ██╗████████╗
            ██╔════╝██║   ██║██╔════╝████╗  ██║╚══██╔══╝
            █████╗  ██║   ██║█████╗  ██╔██╗ ██║   ██║
            ██╔══╝  ╚██╗ ██╔╝██╔══╝  ██║╚██╗██║   ██║
            ███████╗ ╚████╔╝ ███████╗██║ ╚████║   ██║
            ╚══════╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝   ╚═╝
             -->
            <b-tab-item label="Evènement" icon="comment-dots" :visible="activeTab === 3 && selectedPlots.length > 0">

                <form @submit.prevent="addPostEvent">

                    <!-- EVENT TYPE -->
                    <b-field label="Type d'évènement">
                        <b-select placeholder="Sélectionner un type"
                                  v-model="postEvent.evenement"
                                  required>
                            <option
                                    v-for="option in events"
                                    :value="option.idevenement"
                                    :key="option.idevenement">
                                {{ option.libevenement }}
                            </option>
                        </b-select>
                    </b-field>

                    <!-- DATE -->
                    <b-field label="Date de l'évènement">
                        <b-datetimepicker v-model="postEvent.date"
                                          placeholder="Sélectionner...">
                        </b-datetimepicker>
                    </b-field>

                    <!-- COMMENT -->
                    <b-field label="Commentaire sur l'évènement">
                        <b-input maxlength="200"
                                 type="textarea"
                                 v-model="postEvent.comment">
                        </b-input>
                    </b-field>

                    <div class="form-group">
                        <b-button icon-left="save"
                                  native-type="submit"
                                  class="is-primary">
                            Enregistrer
                        </b-button>
                    </div>
                </form>
            </b-tab-item>

            <!--
            ███████╗██╗   ██╗███████╗███╗   ██╗████████╗███████╗    ██╗     ██╗███████╗████████╗
            ██╔════╝██║   ██║██╔════╝████╗  ██║╚══██╔══╝██╔════╝    ██║     ██║██╔════╝╚══██╔══╝
            █████╗  ██║   ██║█████╗  ██╔██╗ ██║   ██║   ███████╗    ██║     ██║███████╗   ██║
            ██╔══╝  ╚██╗ ██╔╝██╔══╝  ██║╚██╗██║   ██║   ╚════██║    ██║     ██║╚════██║   ██║
            ███████╗ ╚████╔╝ ███████╗██║ ╚████║   ██║   ███████║    ███████╗██║███████║   ██║
            ╚══════╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝    ╚══════╝╚═╝╚══════╝   ╚═╝
             -->
            <b-tab-item label="Evènements" icon="comment-dots" :visible="activeTab === 4">

            </b-tab-item>

        </b-tabs>


        <b-modal
                :active.sync="isComponentModalActive"
                has-modal-card
                full-screen
                :can-cancel="true">

            <!-- Fullpage modal to display table -->
            <ModalOwner
                    :ownerInfo="ownerInfo"
                    :otherParcels="otherParcels"
                    :xy="xy">
            </ModalOwner>

        </b-modal>



    </div>
</template>

<script>
    import {bus} from '@/main';
    import ModalOwner from "./ModalOwner";

    export default {
        name: "ManageParcelle",
        components: {ModalOwner},
        computed: {
            // Search filter on Aires
            getFilteredAires() {
                return this.aires.filter((option) => {
                    return option.libaire
                        .toString()
                        .toLowerCase()
                        .indexOf(this.reduceAires.toLowerCase()) >= 0
                })
            },
        },
        mounted() {
            this.GetDictionary();
            this.GetEvents();
            this.GetContact();
        },
        beforeDestroy() {
            // Reset action when we leave the component
            console.log("before destroy")
            this.modeAddPlots(false);
            this.modeEditPlots(false);
            bus.$off('selectedPlotToMod'); // Eviter les conflits avec la partie event
        },
        created() {
            bus.$on('idpersonne', (id) => {
                // this.$axios.get(`getParcelIdprocpte?id=${ encodeURI(e.data[0].idprocpte) }`)
                this.$axios.get(`getParcelIdpersonne?id=${ encodeURI(id) }`)
                    .then(function (res) {
                        this.otherParcels = res.data;
                    }.bind(this))
                    .catch(error => console.log(error));
            })
            // Receive new created plot id
            bus.$on('retrieveOwner', (e, xy) => {
                if (e.data !== "DENY") {
                    this.xy = xy;
                    this.ownerInfo = e.data;
                    bus.$emit('idpersonne', e.data[0].idpersonne)
                    this.isComponentModalActive = true;
                } else if (e.data === "DENY") {
                    this.$buefy.dialog.alert({
                        title: 'Permission manquante',
                        message: 'Vous n\'avez pas les permissions requises pour accéder à cette information.' ,
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    })
                }
            });

            // Receive new created plot id
            bus.$on('selectedPlots', (e, c) => {
                this.selectedPlots = e;
                if (e.length === 1) {
                    this.$axios.get(`aires?idparcelle=${e[0].idparcelle}&commune=${e[0].commune}`) // Send idparcelle to filter and order by position
                        .then(function (response) {
                            this.aires = response.data
                        }.bind(this))
                        .catch(error => console.log(error));
                }
                this.isOpen = e.length - 1;
                if (!this.postEvent.refSAFER > 0) {
                    this.postEvent.refSAFER = c;
                }
            });
            // Retrieve data to mod
            bus.$on('selectedPlotToMod', (e) => {
                if(this.modParcelleSwitch) {
                    this.$axios.get(`modParcelle?id=${e.idparcelle}`)
                            .then(function (response) {
                                let a = response.data[0].idaire
                                this.$axios.get(`aires?idparcelle=${e.idparcelle}&commune=${response.data[0].commune}`) // Send idparcelle to filter and order by position
                                    .then(function (res) {
                                        this.aires = res.data
                                        if (a) {
                                            let b = this.aires.filter((el) => {
                                                return el.idaire === a
                                            });
                                            console.log(b)
                                            this.selectedAire = b[0];
                                            this.reduceAires = this.selectedAire.libaire;
                                            this.manualAire = '';
                                            this.addNewAire = false;
                                        } else {
                                            this.reduceAires = '';
                                            this.selectedAire = '';
                                            this.manualAire = '';
                                            this.addNewAire = false;
                                        }
                                    }.bind(this))
                                    .catch(error => console.log(error));
                                this.selectedPlots = []; // Reset plot
                                this.selectedPlots.push(response.data[0]);
                                this.postTags = [];
                                response.data[0].espece.forEach( (e) => {
                                    this.postTags.push(e);
                                });
                                this.postEvent.habitat = response.data[0].idhabitat;
                                this.postEvent.interet = response.data[0].idinteret;
                                this.postEvent.refCEN = response.data[0].idcontact_cen;
                                this.postEvent.dossier = response.data[0].iddossier;
                                this.postEvent.refSAFER = response.data[0].idcontact_safer;
                                this.postEvent.usage = response.data[0].idusage;
                                this.postEvent.argumentaire = response.data[0].argumentaire;
                        }.bind(this))
                        .catch(error => console.log(error));
                }
            });
        },
        methods: {
            beforeAdding(tag) {
                let duplicate = [];
                this.postTags.forEach((p) => {
                    p.idespece === tag.idespece ? duplicate.push('ko') : null;
                });
                return duplicate.length === 0;
            },
            sumContenance() {
                if (this.selectedPlots == null) {
                    return 0;
                }
                return this.selectedPlots.reduce(function (a, b) {
                    return b['contenance'] == null ? a : a + b['contenance'];
                }, 0);
            },
            // Search filter on species
            getFilteredTags(text) {
                this.filteredTags = this.species.filter((option) => {
                    return option.nomvern
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },
            GetDictionary() {
                this.$axios.get('dictionary')
                    .then(function (response) {
                        this.dictionary = response.data;
                        // species
                        this.species = response.data.especes;
                        this.usages = response.data.usages;
                        this.dossiers = response.data.dossiers
                        this.filteredTags = this.species;
                    }.bind(this))
                    .catch(error => console.log(error));
            },
            GetContact(){
                this.$axios.get('refCEN')
                    .then(function (response) {
                        this.refCEN = response.data;
                    }.bind(this))
                    .catch(error => console.log(error));
                this.$axios.get('refSAFER')
                    .then(function (response) {
                        this.refSAFER = response.data;
                    }.bind(this))
                    .catch(error => console.log(error));
            },
            GetEvents() {
                this.$axios.get('possibleevents')
                    .then(function (response) {
                        this.events = response.data.events;
                    }.bind(this))
                    .catch(error => console.log(error));
            },
            addPostEvent() {
                if(this.postEvent.date) {

                    // Loading start
                    const loadingComponent = this.$buefy.loading.open({
                        container: null
                    });

                    let delta = this.postEvent.date.getTimezoneOffset();
                    this.postEvent.date = new Date(this.postEvent.date.getTime() - (delta * 60000));
                    this.$axios({
                        method: 'post',
                        url: 'putParcelle',
                        data: {
                            parcelles: this.selectedPlots,
                            event: this.postEvent,
                            especes: this.postTags,
                            aire: this.manualAire ? this.manualAire : this.selectedAire,
                        }
                    })
                        .then(function () {
                            this.addParcelleSwitch = false;
                            this.modeAddPlots(this.addParcelleSwitch);
                            this.selectedPlots = [];
                            bus.$emit('updateMap', true);
                            this.GetDictionary(); // To retreive aires if created or deleted
                            this.activeTab = 0;

                            // Loading end
                            loadingComponent.close()

                        }.bind(this))
                        .catch(function (erreur) {
                            //On traite ici les erreurs éventuellement survenues
                            console.log(erreur);
                        });
                }
            },
            savecard() {
                this.activeTab = 3;
            },
            resetAire(){
                this.selectedAire = '';
                this.reduceAires = '';
                this.manualAire = null;
            },
            /*
            ███╗   ███╗ ██████╗ ██████╗
            ████╗ ████║██╔═══██╗██╔══██╗
            ██╔████╔██║██║   ██║██║  ██║
            ██║╚██╔╝██║██║   ██║██║  ██║
            ██║ ╚═╝ ██║╚██████╔╝██████╔╝
            ╚═╝     ╚═╝ ╚═════╝ ╚═════╝
             */

            modSaveParcelle() {

                // Loading start
                const loadingComponent = this.$buefy.loading.open({
                    container: null
                });

                this.$axios({
                    method: 'PUT',
                    url: 'updateParcelle',
                    data: { parcelle: this.selectedPlots[0].idparcelle,
                            especes: this.postTags,
                            habitat: this.postEvent.habitat,
                            interet: this.postEvent.interet,
                            refCEN: this.postEvent.refCEN,
                            refSAFER: this.postEvent.refSAFER,
                            usage: this.postEvent.usage,
                            dossier: this.postEvent.dossier,
                            argumentaire: this.postEvent.argumentaire,
                            aire: this.manualAire ? this.manualAire : this.selectedAire,
                    }
                })
                    .then(function () {
                        this.modParcelleSwitch = false;
                        this.resetAll();
                        bus.$emit('updateMap', true);
                        this.GetDictionary(); // To retreive aires if created or deleted
                        // Loading end
                        loadingComponent.close()
                    }.bind(this))
                    .catch(function (erreur) {
                        //On traite ici les erreurs éventuellement survenues
                        console.log(erreur);
                    });
            },
            confirmDelete(){
                this.$buefy.dialog.confirm({
                    title: 'Suppression d\'une parcelle',
                    message: 'Etes-vous sûr de vouloir <b>supprimer</b> cette parcelle et les informations associées (évènements, PDF...) ? <b>Cet action est irréversible.</b>',
                    confirmText: 'Oui, supprimer',
                    cancelText: 'Non, annuler',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.modDeleteParcelle()
                })
            },
            modDeleteParcelle() {
                this.$axios({
                    method: 'DELETE',
                    url: 'deleteParcelle/',
                    data: { parcelle: this.selectedPlots[0].idparcelle }
                })
                    .then(function () {
                        bus.$emit('updateMap', true);
                        this.GetDictionary(); // To retreive aires if created or deleted
                        this.resetAll()
                    }.bind(this))
                    .catch(function (erreur) {
                        //On traite ici les erreurs éventuellement survenues
                        console.log(erreur);
                    });
            },
            resetAll() {
                // Reset all fields
                this.selectedPlots = [];
                this.postTags = [];
                this.postEvent = {};
                this.selectedAire = '';
                this.reduceAires = '';
                this.manualAire = null;
            },
            remove: function (e) {
                bus.$emit('remove', e);
            },
            mouseoverHighlight: function (e, l, a) {
                var send = {
                    val: e,
                    layer: l,
                    action: a,
                }
                bus.$emit('highlight', send);
            },
            /*
            ███████╗██╗    ██╗██╗████████╗ ██████╗██╗  ██╗███████╗███████╗
            ██╔════╝██║    ██║██║╚══██╔══╝██╔════╝██║  ██║██╔════╝██╔════╝
            ███████╗██║ █╗ ██║██║   ██║   ██║     ███████║█████╗  ███████╗
            ╚════██║██║███╗██║██║   ██║   ██║     ██╔══██║██╔══╝  ╚════██║
            ███████║╚███╔███╔╝██║   ██║   ╚██████╗██║  ██║███████╗███████║
            ╚══════╝ ╚══╝╚══╝ ╚═╝   ╚═╝    ╚═════╝╚═╝  ╚═╝╚══════╝╚══════╝
            */
            ownerPlots(value) {
                bus.$emit('getOwner', value);
                value ? this.$buefy.snackbar.open(
                    {
                        message : `Infos propriétaire`,
                        type: 'is-info',
                        position: 'is-bottom-left'
                    }) : null;
            },
            modeAddPlots: function (value) {
                this.activeTab = 0;
                this.postTags = []; // Reset before add
                this.postEvent = {}; // Reset before add
                this.resetAll();
                value ? this.$buefy.snackbar.open(
                    {
                        message : `Ajout de parcelles`,
                        type: 'is-success',
                        position: 'is-bottom-left'
                    }) : null;
                bus.$emit('triggerModeAddPlots', value);
            },
            modeEditPlots: function (value) {
                this.selectedPlots = [];
                this.resetAll();
                value ? this.$buefy.snackbar.open(
                    {
                        message : `Modification de parcelles`,
                        type: 'is-warning',
                        position: 'is-bottom-left'
                    }) : null;
            },
        },
        data: function () {
            return {
                // Species list
                species: [],
                usages: [],
                filteredTags: [],
                postTags: [],
                tags: [],

                // Aires list
                aires: [],                  // All aires from db
                reduceAires: '',          // Filter from input text
                selectedAire: '',         // Post object from list
                manualAire: null,         // Post object from list
                addNewAire: false,          // Switch to new field

                isSelectOnly: false,

                // Save modal windows
                isCardModalActive: false,
                // Dictionary
                dictionary: [],
                events: null,
                // Save state
                save: false,
                activeTab: 0,
                // Data
                postEvent: {},

            // Accordion Effect
                isOpen: 0,
                // Add / mod plots status
                addParcelleSwitch: false,
                modParcelleSwitch: false,
                ownerParcelleSwitch: false,
                // Selected plots
                selectedPlots: [],
                refCEN: null,
                refSAFER: null,

                isComponentModalActive: false,
                ownerInfo: {},
                otherParcels: {},
                // Get X Y clicked for modalOwner tooltip
                xy: [],
            }
        },
    }
</script>

<style scoped>

</style>
