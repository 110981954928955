<template>
  <div class="home">
    <GalleryFoncicen></GalleryFoncicen>
  </div>
</template>

<script>
// @ is an alias to /src

import GalleryFoncicen from "../components/Gallery";
export default {
  name: 'HomeFoncicen',
  components: {GalleryFoncicen},
}
</script>
