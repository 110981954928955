<template>
    <div id="app">
        <div v-bind:class="{ grayscale : isGrayscale }">
            <div id="mainmap">
                <b-button
                    icon-left="paint-roller"
                    :label="isGrayscale ? 'Couleur' : 'Niveau de gris'"
                    size="is-small"
                    type="is-info"
                    style="z-index: 1001; margin-left: 60px; margin-top: 10px"
                    @click="setGrayScale()">
                </b-button>

            </div>
        </div>
    </div>
</template>

<script>
    /*
     */
    import L from 'leaflet';
    import '../../node_modules/leaflet/dist/leaflet.css';
    import 'leaflet.vectorgrid';
    import 'leaflet-draw';
    import '../../node_modules/leaflet-draw/dist/leaflet.draw.css';
    import 'leaflet.pattern';
    import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';
    import '../../node_modules/leaflet-geosearch/dist/geosearch.css';
    import {bus} from '@/main';
    import store from "@/components/store";
    // import * as turf from '@turf/turf'

    export default {
        name: "MapSites",
        mounted() {
            // Base map and background layers
            this.initMap();
            this.WMS();
        },
        created() {
            // Trigger reload layer
            bus.$on('refreshStoredMFUParcelles', () => {
                console.log('storedMFUParcelles function with : ', store.getters.colorsite, store.getters.territoire)
                this.storedMFUParcelles(store.getters.colorsite, store.getters.territoire)
            });
            bus.$on('newSpotSites', (x, y) => {
                // Remove old bounds
                this.focusgeom.clearLayers();
                // Add new bounds
                let spot = L.circleMarker([y, x], {
                    radius: 6,
                    color: '#f14668',
                    weight: 4,
                    fillOpacity: 0,
                    myCustomId: 'bounds',
                });
                // Add to map
                spot.addTo(this.focusgeom);
                this.map.setView([y, x], 15);
            });
            bus.$on('newBoundsSites', (e) => {
                // Remove old bounds
                this.focusgeom.clearLayers();
                // Add new bounds
                let bounds = L.geoJSON(JSON.parse(e), {
                    interactive: false,
                    color: '#f14668',
                    weight: 4,
                    fillOpacity: 0,
                    myCustomId: 'bounds',
                });
                // Add to map
                bounds.addTo(this.focusgeom);
                this.map.fitBounds(bounds.getBounds(), {
                    padding: [1, 1]
                });
            });
            // Reload if theme color pref is modified
            bus.$on('changeThemeColorSites', () => {
                this.storedMFUParcelles(store.getters.colorsite, store.getters.territoire)
            });
            bus.$on('addGeoserverlayerSites', (e) => {
                let l = this.geoserverLayers[e.name];
                if ( l !== undefined && this.geoserverLayerGroup.hasLayer(l)) {
                    this.geoserverLayerGroup.removeLayer(l);
                } else {
                    // Add the layer to the Geoserver group
                    const wms = L.tileLayer.wms(`https://opendata.cen-nouvelle-aquitaine.org/${e.repo}/wms?`, {
                        layers: e.name,
                        format: 'image/png',
                        transparent: true,
                        zIndex: 100,
                        minZoom: 8,
                        tiled: true,
                        opacity : e.sliderValue / 10
                    });
                    this.geoserverLayerGroup.addLayer(wms);
                    this.geoserverLayers[e.name] = wms;
                }
            });
            bus.$on('opacityGeoserverlayerSites', (e) => {
                let l = this.geoserverLayers[e.name];
                if ( l !== undefined && this.geoserverLayerGroup.hasLayer(l)) {
                    l.setOpacity(e.sliderValue / 10);
                } else {
                    console.log(`Layer ${e.name} not loaded`)
                }
            });

        },
        beforeDestroy() {
            bus.$off('refreshStoredMFUParcelles');
            bus.$off('newSpotSites');
            bus.$off('newBoundsSites');
            bus.$off('changeThemeColorSites');
            bus.$off('addGeoserverlayerSites');
            bus.$off('opacityGeoserverlayerSites');
        },
        methods: {
            setGrayScale() {
                this.isGrayscale = !this.isGrayscale
            },
            initMap() {
                // Map declaration
                this.map = L.map('mainmap').setView([45.05, 0.6167], 7);
                // GeoServer layers
                this.geoserverLayerGroup.addTo(this.map)
                // Base layers
                let osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 100,
                    attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    zIndex: 1,
                });
                // IGN layers
                const m1 = L.tileLayer(
                    "https://wxs.ign.fr/ortho/geoportail/wmts?SERVICE=WMTS&VERSION=1.0.0" +
                    "&REQUEST=GetTile" +
                    "&STYLE=normal" +
                    "&TILEMATRIXSET=PM" +
                    "&FORMAT=image/jpeg"+
                    "&LAYER=ORTHOIMAGERY.ORTHOPHOTOS"+
                    "&TILEMATRIX={z}" +
                    "&TILEROW={y}" +
                    "&TILECOL={x}",
                    {
                        attribution: 'Fond cartographique &copy; IGN - Données et intégration &copy; CEN Nouvelle-Aquitaine',
                        zIndex: 1,
                        maxZoom: 100,
                    });
                const m6 = L.tileLayer(
                    "https://wxs.ign.fr/ortho/geoportail/wmts?SERVICE=WMTS&VERSION=1.0.0" +
                    "&REQUEST=GetTile" +
                    "&STYLE=normal" +
                    "&TILEMATRIXSET=PM" +
                    "&FORMAT=image/jpeg"+
                    "&LAYER=ORTHOIMAGERY.ORTHOPHOTOS.IRC"+
                    "&TILEMATRIX={z}" +
                    "&TILEROW={y}" +
                    "&TILECOL={x}",
                    {
                        attribution: 'Fond cartographique &copy; IGN - Données et intégration &copy; CEN Nouvelle-Aquitaine',
                        zIndex: 1,
                        maxZoom: 100,
                    });

                const m3 = L.tileLayer("https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
                    {
                        maxZoom: 100,
                        attribution: '&copy; Openstreetmap France | &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> - Données et intégration &copy; CEN Nouvelle-Aquitaine',
                        zIndex: 1,
                    });
                const m4 = L.tileLayer("https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
                    {
                        maxZoom: 100,
                        attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="http://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a> - Données et intégration &copy; CEN Nouvelle-Aquitaine)',
                        zIndex: 1,
                    });

                const scan25 = L.tileLayer.wms(
                    "https://opendata.cen-nouvelle-aquitaine.org/geoserver/acces_appli_web/ows?",
                    {
                        layers: 'SCAN25TOUR_PYR-JPEG_WLD_WM',
                        format: 'image/png',
                        transparent: true,
                        attribution: 'Fond cartographique &copy; IGN - Données et intégration &copy; CEN Nouvelle-Aquitaine',
                        zIndex: 1,
                        maxZoom: 100,
                    });

                // GeoSearch address
                const provider = new OpenStreetMapProvider();
                const searchControl = new GeoSearchControl({
                    provider: provider,
                });
                this.map.addControl(searchControl);

                // Layer control on map
                this.stored = L.layerGroup();
                // Missing plot to add to sites
                this.missingPlots = L.layerGroup();

                // Add default basemap
                osm.addTo(this.map);

                // Add controls
                L.control.layers(
                    {
                        'OSM': osm,
                        "OSM FR": m3,
                        "OSM Topo": m4,
                        "Ortho IGN": m1,
                        "SCAN25 IGN": scan25,
                        "Infra-rouge IGN": m6,
                    },
                    {
                        'Saisie': this.stored
                    }
                )
                    .addTo(this.map);
                // Prepare to receive focus from search sidebar
                this.focusgeom = L.layerGroup();
                this.map.addLayer(this.focusgeom);


            },
            WMS() {
                L.tileLayer.wms('https://opendata.cen-nouvelle-aquitaine.org/geoserver/administratif/wms?', {
                    layers: 'foncier_agg',
                    format: 'image/png',
                    transparent: true,
                    zIndex: 1000,
                    tiled: true,
                    tilesOrigin: -74.047185 + "," + 40.679648,
                    maxZoom: 100

                }).addTo(this.map);
            },
            storedMFUParcelles(color, idt) {
                this.$axios.get(`allMFU?idt=${idt}`)
                    .then(function (response) {
                        if (response.data.length) {
                            // Clear the existing Layers Group
                            this.stored.clearLayers();
                            // Draw each geom
                            response.data.forEach((element) => {
                                let geom = new L.geoJSON(JSON.parse(element.geom), {
                                    style: {
                                        weight: 2,
                                        opacity: 1,
                                        color: 'black',
                                        fillOpacity: 0.6,
                                        fillColor: color === "statut" ? element.color : this.createColor(element.nom_site)
                                    }
                                });

                                // Set idparcelle as layer id to retreive layer
                                geom.id = element.idparcelle;
                                // Popup on mouseover

                                    geom.bindPopup(
                                        `<b style="color: green; text-transform: uppercase;">${element.nom_site === null ? '' : element.nom_site}</b><br>` +
                                        `<b>Date début MFU:</b> ${element.date_debut_mfu}` +
                                        '<br><b>Code site: </b>' +
                                        element.codesite +
                                        '<br><b>Commune: </b>' +
                                        element.nom_commune +
                                        '<br><br><b>ID parcelle: </b>' +
                                        element.idparcelle +
                                        '<br><b>Section: </b>' +
                                        element.section +
                                        '<br><b>Numéro: </b>' +
                                        element.numero +
                                        '<br><b>Contenance: </b>' +
                                        this.contenanceToSurface(element.contenance) +
                                        '<br>⏮️ ' +
                                        element.last_libevenement +
                                        '</p></b>');

                                    // Action on each polygons
                                    geom.on('mouseover', (e) => {
                                        let popup = e.target.getPopup();
                                        popup.setLatLng(e.latlng).openOn(this.map);
                                    });
                                    geom.on('mouseout', (e) => {
                                        e.target.closePopup();
                                    });
                                    geom.on('mousemove', (e) => {
                                        e.target.closePopup();
                                        let popup = e.target.getPopup();
                                        popup.setLatLng(e.latlng).openOn(this.map);
                                    });
                                    // On right-click on the polygon
                                    geom.on('contextmenu', () => {
                                        if (element.idsite) {
                                            bus.$emit('getInfoSite', element.idsite)
                                        }

                                    });

                                // Event on click to modifiy if codesite is not NULL
                                if (element.codesite !== null) {
                                    geom.on('click', (e) => {
                                        bus.$emit('attachThisPlot', element.idparcelle)
                                        const currentColor = e.layer.options.color;
                                        if (currentColor === 'black') {
                                            // Change the color to a new color when clicked
                                            e.target.setStyle({ color: 'blue', weight: 5,});
                                        } else {
                                            // Revert the color to red when clicked again
                                            e.target.setStyle({ color: 'black', weight: 2});
                                        }
                                    });
                                }
                                // Add entity to the layers group
                                this.stored.addLayer(geom);

                                // If codesite is NULL, put a marker on top of the plot
                                if (element.codesite === null) {
                                    let circle = L.geoJSON(JSON.parse(element.geom_point), {
                                        pointToLayer: function (feature, latlng) {
                                            return L.circleMarker(latlng, {
                                                color: 'red',
                                                fillColor: 'red',
                                                fillOpacity: 0.8,
                                                radius: 10
                                            });
                                        }
                                    });
                                    // Event on click to add to a site if codesite is NULL
                                    circle.on('click', (e) => {
                                        // Attach an orphean plot to a site
                                        bus.$emit('attachThisPlot', element.idparcelle)
                                        const currentColor = e.layer.options.color;
                                        if (currentColor === 'red') {
                                            // Change the color to a new color when clicked
                                            e.target.setStyle({ color: 'blue', fillColor: 'blue' });
                                        } else {
                                            // Revert the color to red when clicked again
                                            e.target.setStyle({ color: 'red', fillColor: 'red' });
                                        }
                                    });
                                    this.stored.addLayer(circle.bringToFront());
                                }
                            });

                        } else { // No stored plots in the database
                            this.stored.clearLayers();
                        }
                    }.bind(this))
                    .catch(error => console.log(error));
                this.map.addLayer(this.stored)
                this.map.addLayer(this.missingPlots);
            },

            createColor(site) {
                if (site === null) {
                    return '#333333'
                }
                // Simple DJB2 hash function
                let hash = 5381;
                for (let i = 0; i < site.length; i++) {
                    hash = (hash * 33) ^ site.charCodeAt(i);
                }

                // Convert the hash to an RGB color
                const r = (hash & 0xFF);
                const g = ((hash >> 8) & 0xFF);
                const b = ((hash >> 16) & 0xFF);

                // Format the RGB values as an HTML color code
                return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
            },

            ToastRemove() {
                this.$buefy.toast.open({
                    duration: 300,
                    message: `Sélection supprimée`,
                    position: 'is-top',
                    type: 'is-danger'
                })
            },

            ToastAdd() {
                this.$buefy.toast.open({
                    duration: 300,
                    message: `Sélection ajoutée`,
                    position: 'is-top',
                    type: 'is-success'
                })
            },
        },
        data() { /* Data properties will go here */
            return {
                // Geoserver variables
                geoserverLayerGroup: L.layerGroup(),
                geoserverLayers: {},
                // Grayscale button
                isGrayscale: false,
                // Zoom min for parcelle
                zoomParcelle: 16,
                // Zoom max for aires
                zoomAire: 12,
                //
                focusgeom: null,
                // Dpt
                dpt: 24
            }
        }
    }
</script>

<style scoped>
    #mainmap {
        width: 100%;
        height: calc(100vh - 80px);
        z-index: 1;
    }
</style>

<style>
/* Trick to switch in B&W */
div.grayscale .leaflet-tile {
    filter: grayscale(100%);
}
</style>