<template>
    <b-carousel :indicator-inside="false">
        <b-carousel-item v-for="(item, i) in items.length" :key="i">
            <span class="image">
              <img :src="items[i].image" height="130%">
            </span>
        </b-carousel-item>
        <template slot="indicators" slot-scope="props">
            <span class="al image">
                <img :src="items[props.i].image" :title="items[props.i].title">
            </span>
        </template>
    </b-carousel>
</template>

<script>
    export default {
        name: "GalleryFoncicen",
        methods: {
        },
        data: function () {
            return {
                items: [
                            {
                                title: "Slide 1",
                                image: require("@/assets/gallery/AS_gouilles_Tourbiere_de_la_Mazure_2017.jpg")
                            },
                            {
                                title: "Slide 2",
                                image: require("@/assets/gallery/cazine_francois_porcher.jpg")
                            },
                            {
                                title: "Slide 3",
                                image: require("@/assets/gallery/etang_brudoux.jpg")
                            },
                            {
                                title: "Slide 4",
                                image: require("@/assets/gallery/gouille_longeyroux.jpg")
                            },
                            {
                                title: "Slide 5",
                                image: require("@/assets/gallery/guizengeard_c_auburtin.jpg")
                            }
                    ]
            }
        }
    }
</script>

<style>
    .is-active .al img {
        filter: grayscale(0%);
    }
    .al img {
        filter: grayscale(0%);
    }
</style>
