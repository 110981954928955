<template>
    <div>
        <section class="section">
            <div class="container">

                <div class="columns">

                    <div class="column">
                        <GeomFilter
                            mode="animationfonciere">
                        </GeomFilter>
                    </div>

                    <div class="column">
                        <AdvancedSearch>
                        </AdvancedSearch>
                    </div>

                </div>


            </div>
        </section>
    </div>
</template>

<script>

    import GeomFilter from "../components/GeomFilters";
    import AdvancedSearch from "../components/AdvancedSearch";
    export default {
        name: "AnimationFoncicen",
        components: {AdvancedSearch, GeomFilter},
        mounted() {
        },
        methods: {
        },
    }
</script>

<style scoped>

</style>